import React from "react";

import Link from "../navigation/Link";

import { Alert } from "react-bootstrap";
import ApiConnector from "../../api/ApiConnector";

class Register extends React.Component {
  state = {
    displayName: "",
    email: this.readUrlEmail(),
    password: "",
    passTwo: "",
    errorMessage: null,
    infoMessage: this.readUrlEmail()
      ? "Selected email is not yet registered. Please create an account to get started"
      : null,
    formSubmitting: false,
  };

  readUrlEmail() {
    try {
      return atob(this.props.chosenEmail);
    } catch (e) {
      return "";
    }
  }

  formValidations = () => {
    this.setState({
      infoMessage: null,
    });
    if (this.state.password !== this.state.passTwo) {
      this.setState({ errorMessage: "Passwords do not match" });
    } else {
      this.setState({
        errorMessage: null,
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value }, () => {
      this.formValidations();
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      formSubmitting: true,
    });

    const { displayName, email, password } = this.state;

    const successCallback = () => {
      this.props.registerUser(displayName);
    };
    const errorCallback = (error) => {
      if (error.message !== null) {
        this.setState({ errorMessage: error.message, formSubmitting: false });
      } else {
        this.setState({ errorMessage: null, formSubmitting: false });
      }
    };

    ApiConnector.createUser(
      { email, password },
      successCallback,
      errorCallback
    );
  };

  render() {
    const { handleChange, state, handleSubmit } = this;
    const {
      formSubmitting,
      displayName,
      email,
      password,
      passTwo,
      errorMessage,
      infoMessage,
    } = state;
    return (
      <div className="loginContainer">
        <div className="formRow formLogoRow pt-5 pb-3">
          <div className="brandLogo" />
        </div>
        <form className="card" onSubmit={handleSubmit}>
          <h2 className="text-center">Create Account</h2>

          <div className="formRow formRowLabelEntry">
            <div className="label">Full Name:</div>
            <input
              type="text"
              id="displayName"
              placeholder="Preferred name"
              name="displayName"
              autoComplete="full name"
              required
              value={displayName}
              onChange={handleChange}
            />
          </div>

          <div className="formRow formRowLabelEntry">
            <div className="label">Email:</div>
            <input
              type="email"
              id="email"
              placeholder="This email address will be your user id"
              name="email"
              autoComplete="email"
              required
              value={email}
              onChange={handleChange}
            />
          </div>
          <div className="formRow formRowLabelEntry">
            <div className="label">Password:</div>
            <div className="formRowDualSideBySide">
              <input
                type="password"
                id="password"
                placeholder="Password must be 6+ characters"
                name="password"
                autoComplete="new-password"
                required
                value={password}
                onChange={handleChange}
              />
              <input
                type="password"
                id="passTwo"
                placeholder="Repeat password"
                name="passTwo"
                autoComplete="new-password"
                required
                value={passTwo}
                onChange={handleChange}
              />
            </div>
          </div>
          {infoMessage !== null && <Alert variant="dark">{infoMessage}</Alert>}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <div className="formRow formRowButtons dualButtons">
            <Link to={`/login/${btoa(email)}`} className="bright button">
              Login
            </Link>
            <button
              className={`button bright solid ${formSubmitting && "disabled"}`}
              type="submit"
            >
              Register
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default Register;
