import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  TextBlock,
  Image,
  Interactive,
  Question,
  Game,
  TextBlockCollapse,
  TextBlockCourseTitle,
  TextBlockListGroup1,
  TextBlockListGroup2,
  TextBlockModal,
  TextBlockNumberList1,
  TextBlockNumberList2,
  TextBlockPopOver,
  TextBlockText,
  TextBlockUnorderedList,
  ImageBlockCard1,
  ImageBlockCard2,
  ImageBlockCard3,
  ImageBlockCard4,
  ImageBlockImageBannerLeft,
  ImageBlockImageBannerRight,
  ImageBlockImageCarousel,
  ImageBlockTextImageLeft,
  ImageBlockTextImageRight,
  ElementBlockProgress,
  ElementBlockQuote1,
  ElementBlockQuote2,
  ElementBlockQuote3,
  ElementBlockQuote4,
  InteractiveBlockAccordion,
  InteractiveBlockNextPage,
  InteractiveBlockTabsHorizontal,
  InteractiveBlockTabsVertical,
  QuizBlockEssay,
  QuizBlockFillInTheBlank,
  QuizBlockMultipleChoice,
  QuizBlockMultipleSelect,
  MediaBlockAudioPlayer,
  MediaBlockFullRowVideo,
  MediaBlockVideo,
  Media,
  Elements
} from "../graphics/Icons";
import {
  templateLibrary,
  templateConfigDefaults,
} from "../../utils/templateLibrary";

// the keys inside the different Blocks arrays map to the templateLibrary keys
const TextBlocks = [
  {
    key: "textBlockCourseTitle",
    icon: TextBlockCourseTitle,
    label: "Course Title",
  },
  {
    key: "textBlockText",
    icon: TextBlockText,
    label: "Text",
  },
  {
    key: "textBlockCollapse",
    icon: TextBlockCollapse,
    label: "Collapse",
  },
  {
    key: "textBlockPopOver",
    icon: TextBlockPopOver,
    label: "PopOver",
  },
  {
    key: "textBlockListGroup1",
    icon: TextBlockListGroup1,
    label: "List Group 1",
  },
  {
    key: "textBlockListGroup2",
    icon: TextBlockListGroup2,
    label: "List Group 2",
  },
  {
    key: "textBlockUnorderedList",
    icon: TextBlockUnorderedList,
    label: "Unordered List",
  },
  {
    key: "textBlockNumberList1",
    icon: TextBlockNumberList1,
    label: "Number List 1",
  },
  {
    key: "textBlockNumberList2",
    icon: TextBlockNumberList2,
    label: "Number List 2",
  },
  {
    key: "textBlockModal",
    icon: TextBlockModal,
    label: "Modal",
  },
];

const ImageBlocks = [
  {
    key: "imageBlockCard1",
    icon: ImageBlockCard1,
    label: "Card 1",
  },
  {
    key: "imageBlockCard2",
    icon: ImageBlockCard2,
    label: "Card 2",
  },
  {
    key: "imageBlockCard3",
    icon: ImageBlockCard3,
    label: "Card 3",
  },
  {
    key: "imageBlockCard4",
    icon: ImageBlockCard4,
    label: "Card 4",
  },
  {
    key: "imageBlockImageBannerLeft",
    icon: ImageBlockImageBannerLeft,
    label: "Image Banner Left",
  },
  {
    key: "imageBlockImageBannerRight",
    icon: ImageBlockImageBannerRight,
    label: "Image Banner Right",
  },
  {
    key: "imageBlockImageCarousel",
    icon: ImageBlockImageCarousel,
    label: "Image Carousel",
  },
  {
    key: "imageBlockTextImageLeft",
    icon: ImageBlockTextImageLeft,
    label: "Text & Image Left",
  },
  {
    key: "imageBlockTextImageRight",
    icon: ImageBlockTextImageRight,
    label: "Text & Image Right",
  },
];

const ElementBlocks = [
  {
    key: "elementBlockProgress",
    icon: ElementBlockProgress,
    label: "Progress",
  },
  { key: "elementBlockQuote1", icon: ElementBlockQuote1, label: "Quote 1" },
  { key: "elementBlockQuote2", icon: ElementBlockQuote2, label: "Quote 2" },
  { key: "elementBlockQuote3", icon: ElementBlockQuote3, label: "Quote 3" },
  { key: "elementBlockQuote4", icon: ElementBlockQuote4, label: "Quote 4" },
];

const InteractiveBlocks = [
  {
    key: "interactiveBlockAccordion",
    icon: InteractiveBlockAccordion,
    label: "Accordion",
  },
  {
    key: "interactiveBlockNextPage",
    icon: InteractiveBlockNextPage,
    label: "Next Page",
  },
  {
    key: "interactiveBlockTabsHorizontal",
    icon: InteractiveBlockTabsHorizontal,

    label: "Tabs Horizontal",
  },
  {
    key: "interactiveBlockTabsVertical",
    icon: InteractiveBlockTabsVertical,
    label: "Tabs Vertical",
  },
];

const QuizBlocks = [
  { key: "quizBlockEssay", icon: QuizBlockEssay, label: "Essay" },
  {
    key: "quizBlockFillInTheBlank",
    icon: QuizBlockFillInTheBlank,
    label: "Fill In The Blank",
  },
  {
    key: "quizBlockMultipleChoice",
    icon: QuizBlockMultipleChoice,
    label: "Multiple Choice",
  },
  {
    key: "quizBlockMultipleSelect",
    icon: QuizBlockMultipleSelect,
    label: "Multiple Select",
  },
];

const MediaBlocks = [
  {
    key: "mediaBlockAudioPlayer",
    icon: MediaBlockAudioPlayer,
    label: "Audio Player",
  },
  {
    key: "mediaBlockFullRowVideo",
    icon: MediaBlockFullRowVideo,
    label: "Full Row Video",
  },
  { key: "mediaBlockVideo", icon: MediaBlockVideo, label: "Video" },
];

const GameBlocks = [];

const BlockCategories = [
  {
    key: "textBlocks",
    icon: TextBlock,
    label: "Text Blocks",
    blocks: TextBlocks,
  },
  {
    key: "imageBlocks",
    icon: Image,
    label: "Image Blocks",
    blocks: ImageBlocks,
  },
  {
    key: "elementBlocks",
    icon: Elements,
    label: "Element Blocks",
    blocks: ElementBlocks,
  },
  {
    key: "interactiveBlocks",
    icon: Interactive,
    label: "Interactive Blocks",
    blocks: InteractiveBlocks,
  },
  {
    key: "quizBlocks",
    icon: Question,
    label: "Quiz Blocks",
    blocks: QuizBlocks,
  },
  {
    key: "mediaBlocks",
    icon: Media,
    label: "Media Blocks",
    blocks: MediaBlocks,
  },
  {
    key: "gameBlocks",
    icon: Game,
    label: "Game Blocks",
    blocks: GameBlocks,
  },
];

function ModalAddContentBlock({ onSelection, onHide, show }) {
  const [blockGroup, updateBlockGroup] = useState(0);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);

  const clearErrors = () => {
    // clear previous
    setError(null);
    setWarning(null);
  };

  const setBlockGroup = (index) => {
    clearErrors();
    updateBlockGroup(index);
  };

  const checkActiveClass = (index) =>
    index === blockGroup ? "block-active" : "";

  const selectItem = ({ key, label = "" }) => {
    clearErrors();
    if (templateLibrary[key]) {
      // if (
      //   !templateLibrary[key].advancedTemplate &&
      //   templateLibrary[key].htmlTemplate
      // ) {
      console.log(templateLibrary[key].htmlTemplate);
      onSelection(
        templateLibrary[key].htmlTemplate,
        templateConfigDefaults[key],
        templateLibrary[key].jsonTemplate,
      );
      onHide();
      // }
    } else {
      // setError("Oops, something went wrong. Please try another selection.");
      setWarning(
        `Block "${label}" is not available yet. Please check back later.`
      );
    }
  };

  const generateBlockButtons = (arr) => {
    return arr.map((block, index) => (
      <div
        key={`block-selection-${block.key}-${index}`}
        className={`icon-match block-selection ${templateLibrary[block.key]?.advancedTemplate
            ? `block-advanced ${!templateLibrary[block.key].advancedTemplateReady ? 'block-advanced-pending' : ''}`
            : !templateLibrary[block.key]
              ? "block-pending"
              : ""
          }`}
        onClick={() => {
          selectItem(block);
        }}
      >
        <block.icon />
        <span>{block.label}</span>
      </div>
    ));
  };

  const generateCategoryMenu = () =>
    BlockCategories.map((category, index) => (
      <div
        key={`block-menu-item-${category.key}-${index}`}
        className={`icon-match block-category ${checkActiveClass(index)}`}
        onClick={() => {
          setBlockGroup(index);
        }}
      >
        {category.icon && <category.icon title={category.label} style={{ maxWidth: '60px', maxHeight: '50px' }} />}
        <span>{category.label}</span>
      </div>
    ));

  const generateCategoryPages = () =>
    BlockCategories.map(
      (category, index) =>
        checkActiveClass(index) && (
          <div
            key={`block-category-${category.key}-${index}`}
            className="block-tiles"
          >
            {category.blocks.length > 0 ? (
              generateBlockButtons(category.blocks)
            ) : !warning ? (
              setWarning(
                `category "${category.label}" is not available yet. Please check back later.`
              )
            ) : (
                  <div />
                )}
          </div>
        )
    );

  // TODO - passing in prop -> props.colCount - which indicates how many columns in current section / row.
  // This will be helpful for more advanced templates, if they are only compatible with a 1 column, 2, or 3
  return (
    <div className={window.ld_author_main}>
      <Modal className="modal-minimal modal-fluid" show={show} onHide={onHide}>
        <button className="unstyled modal-close" onClick={onHide}>&times;</button>
        <div className="block-library">
          <div className="block-categories">
            <div className="block-categories-label">Block Type</div>
            {generateCategoryMenu()}
          </div>
          <div className="block-selections">
            <div className="block-selections-label">
              Click on the block type you want to insert to add it to the page
            </div>
            {generateCategoryPages()}
            {error && (
              <div className="mt-3 alert alert-danger mx-3">{error}</div>
            )}
            {warning && (
              <div className="mt-3 alert alert-warning mx-3">{warning}</div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalAddContentBlock;
