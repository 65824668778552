import React from "react";
import Link from "../navigation/Link";
import { defaultAvatar } from "../../utils/profile";

const ProfileNavLink = (props) => {
  const { user } = props || {};
  const { avatarImg = defaultAvatar } = user || {};
  return (
    <Link className="profile p-3" to="/profile">
      <div
        className="avatar"
        style={{
          backgroundImage: `url("${avatarImg}")`,
        }}
      />
    </Link>
  );
};

export default ProfileNavLink;
