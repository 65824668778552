import React from "react";
import { defaultAvatar } from "../../utils/profile";
import Link from "../navigation/Link";
import { BackHome } from "../graphics/Icons";
import { Modal, Button } from "react-bootstrap";
import ApiConnector from "../../api/ApiConnector";

import { BASE_PATH } from "../../App";
import { navigate } from "@reach/router";

const Profile = (props) => {
  const { user } = props || {};
  const { displayName, email, avatarImg = defaultAvatar } = user || {};
  const [modalState, updateModalState] = React.useState({});
  const [profileError, setProfileError] = React.useState("");

  const logOut = async () => {
    props.clearUser();

    await ApiConnector.logOut();

    navigate(BASE_PATH + "/");
  };

  const handleModalClose = () => {
    setProfileError("");
    updateModalState({ ...modalState, modalShow: false });
  };

  const deleteConfirmed = () =>
    user
      .delete()
      .then(logOut)
      .catch((error) => {
        setProfileError(error.msg);
        // handleModalClose();
      });

  const deleteAccount = async () => {
    updateModalState({
      modalContent: (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Permanent Action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to permanently delete this account? This
            action cannot be undone.
            {profileError && (
              <div className="alert alert-danger mt-2">{profileError}</div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteConfirmed();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </>
      ),
      modalShow: true,
    });
  };

  const upgradeAccount = () => {};

  return (
    <>
      <div className="container py-3 pt-4">
        <Link to="/" className="btn-home">
          <BackHome />
          Home
        </Link>
      </div>
      <div className="container py-3">
        <div className="card">
          <div className="profileContainer">
            <div className="profile">
              <span
                className="cardThumbnail m-5"
                style={{
                  backgroundImage: `url(${avatarImg})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
              <div className="cardDescription">
                <span className="projectDetails">
                  <p>Profile information: </p>
                  <div className="projectName">
                    <strong>{displayName}</strong>
                  </div>
                  <span className="projectDescription">{email}</span>
                  <hr />
                  <span>Account Type: </span>
                  <strong>Free</strong>
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="formRow pt-2 pb-3 formRowButtons">
              <button className="btn btn-primary" onClick={upgradeAccount}>
                Upgrade Account
              </button>
              <button className="btn btn-danger" onClick={deleteAccount}>
                Delete Account
              </button>
              <button className="btn btn-outline-secondary" onClick={logOut}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modalState.modalShow} onHide={handleModalClose}>
        {modalState.modalContent}
      </Modal>
    </>
  );
};

export default Profile;
