import React from "react";
import { Plus } from "../graphics/Icons";

const AddBlockBtn = (props) => (
  <div className="content-editable-col empty" onClick={props.handleClick}>
    <div className="add-content-block">
      <Plus />
      <br />
      Add Block
    </div>
  </div>
);

export default AddBlockBtn;
