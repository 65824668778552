/* eslint-disable no-loop-func */
import React from "react";
import fb from "../../api/Firebase";
import { navigate } from "@reach/router";
import Loading from "../graphics/Loading";
import { getSectionClassName } from "../../utils/rendering";
import ApiConnector from "../../api/ApiConnector";
import menuIcon from "../../ninja-main/media/player/menu.png";
import closeIcon from "../../ninja-main/media/player/close_white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { renderToString } from "react-dom/server";
import AdvancedContent from "../advancedEditing/AdvancedContent";

class ProjectPreview extends React.Component {
  state = {
    name: "",
    scorm: "",
    pages: [],
    projectLoaded: false,
    activePageIndex: 0,
    activeSubPageIndex: 0,
    menuStatus: {
      collapsed: {},
      visited: {},
      showing: false,
    } /* contains values of collapsed items */,
    nextDisabled: false,
    prevDisabled: false,
  };

  constructor(props) {
    super(props);
    this.resizer = React.createRef();
  }

  getMenuStatus = () => {
    const ld_menuStatus =
      localStorage.getItem("ld_menuStatus") ||
      JSON.stringify(this.state.menuStatus);
    let menuStatus = this.state.menuStatus;
    try {
      menuStatus = JSON.parse(ld_menuStatus);
    } catch (e) {
      console.log(e);
    }
    console.log(menuStatus);
    this.setState({
      menuStatus,
    });
  };

  setMenuStatus = (type, payload, e) => {
    e && e.preventDefault && e.preventDefault();
    let menuStatus = this.state.menuStatus;

    menuStatus = {
      ...menuStatus,
      [type]:
        typeof payload === "object"
          ? {
              ...menuStatus[type],
              ...payload,
            }
          : payload,
    };

    localStorage.setItem("ld_menuStatus", JSON.stringify(menuStatus));

    this.setState({
      menuStatus,
    });
  };

  componentDidMount() {
    document.querySelector("body").classList.remove(window.ld_author_main);
    document.querySelector("body").classList.add("course-preview");
    const { userID, projectID } = this.props;
    const ref = fb.database().ref(`projects/${userID}/${projectID}`);

    this.getMenuStatus();

    ref.on("value", (snapshot) => {
      let project = snapshot.val();
      if (project) {
        const {
          name,
          scorm,
          description,
          pages,
          activePageIndex = 0,
          activeSubPageIndex = 0,
        } = project;
        this.setState({
          activePageIndex,
          activeSubPageIndex,
          name,
          scorm,
          pages,
          description,
        });
        this.savePageStatus();
      } else {
        navigate("/404");
      }
      this.setState({
        projectLoaded: true,
      });
    });
    [
      "https://code.jquery.com/jquery-3.3.1.min.js",
      "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js",
      "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js",
    ].forEach((customScript) => {
      let myScript = document.createElement("script");
      myScript.setAttribute("src", customScript);
      document.body.appendChild(myScript);
    });
  }

  savePageStatus = () => {
    this.savePages();
    this.updateCourseNavigability();

    // Update current active page visited status
    const { pages = [], activeSubPageIndex, activePageIndex } = this.state;
    const active = pages[activePageIndex] || { subPages: [] };
    const page = active?.module ? active?.subPages?.[activeSubPageIndex] : active;
    this.setMenuStatus("visited", {
      [page.id]: true,
    });
    setTimeout(() => {
      [process.env.PUBLIC_URL + "/ninja-main/js/main.js"].forEach(
        (customScript) => {
          let myScript = document.createElement("script");
          myScript.setAttribute("id", "learning-dojo-script");
          myScript.setAttribute("src", `${customScript}?v=${Date.now()}`);
          document.body.appendChild(myScript);
        }
      );
    }, 50);
  };

  componentWillUnmount() {
    document.querySelector("body").classList.add(window.ld_author_main);
    document.querySelector("body").classList.remove("course-preview");
  }

  renderSection = (section) => {
    const { cols } = section;
    const sectionClassName = getSectionClassName(section);

    let htmlString = cols.reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue.json
          ? renderToString( 
              <AdvancedContent
                json={currentValue.json}
                id={currentValue.id}
              />
            )
          : currentValue.html),
      ""
    );

    if (htmlString.indexOf('container') === -1) {
      htmlString = `<div class="container"><div class="d-flex flex-row">
        ${htmlString}
      </div></div>`;
    }

    return (
      <div
        className={sectionClassName}
        dangerouslySetInnerHTML={{
          __html: cols
            ? htmlString
            : "",
        }}
      ></div>
    );
  };

  renderCurrentPage = () => {
    const { pages = [], activeSubPageIndex, activePageIndex } = this.state;
    const active = pages[activePageIndex];
    const page = active?.module ? active?.subPages?.[activeSubPageIndex] : active;

    return (
      <>
        {page?.sections?.length > 0 ? (
          <>
            {page?.sections?.map((section) => (
              <div key={section.id}>{this.renderSection(section)}</div>
            ))}
          </>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center h-100 text-center">
            <div className="display-4">No content for this page</div>
            <div className="display-5">Add sections in editor to preview</div>
          </div>
        )}
      </>
    );
  };

  navigateToPage = (activePageIndex, activeSubPageIndex, e) => {
    e && e.preventDefault && e.preventDefault();
    this.setState(
      {
        activePageIndex,
        activeSubPageIndex,
      },
      this.savePageStatus
    );
  };

  /**
   * @desc updates state immediately with modified page data, then communicates with ApiConnector to save serverside
   * @param {Object[]} pages
   * @param {Number} activePageIndex
   */
  savePages = async () => {
    const { userID, projectID } = this.props;
    const { pages, activePageIndex, activeSubPageIndex } = this.state;

    try {
      await ApiConnector.updateProject(userID, projectID, {
        pages,
        activePageIndex,
        activeSubPageIndex,
      });
    } catch (err) {
      console.error(err);
    }
  };

  toggleMenu = () => {
    this.setMenuStatus("showing", !this.state.menuStatus.showing);
  };

  updateCourseNavigability = () => {
    const { pages, activePageIndex, activeSubPageIndex } = this.state;
    const currentTopPage = pages && pages[activePageIndex];

    if(!currentTopPage) return false;

    const canNavNextPage = activePageIndex < pages.length - 1;
    const canNavNextSubPage =
      currentTopPage.module &&
      activeSubPageIndex < pages[activePageIndex]?.subPages?.length - 1;

    const canNavPrevPage = activePageIndex > 0;
    const canNavPrevSubPage = currentTopPage.module && activeSubPageIndex > 0;

    this.setState({
      nextDisabled: !canNavNextPage && !canNavNextSubPage,
      prevDisabled: !canNavPrevPage && !canNavPrevSubPage,
    });

    return {
      canNavNextPage,
      canNavNextSubPage,
      canNavPrevPage,
      canNavPrevSubPage,
    };
  };

  goNextPage = () => {
    const { activePageIndex, activeSubPageIndex } = this.state;

    let updatedPageIndex = activePageIndex;
    let updatedSubPageIndex = activeSubPageIndex;

    const {
      canNavNextPage,
      canNavNextSubPage,
    } = this.updateCourseNavigability();

    if (canNavNextSubPage) {
      updatedSubPageIndex++;
    } else if (canNavNextPage) {
      updatedPageIndex++;
      updatedSubPageIndex = 0; // start subPage back to zero with top page advance
    }

    this.setState(
      {
        activePageIndex: updatedPageIndex,
        activeSubPageIndex: updatedSubPageIndex,
      },
      this.savePageStatus
    );
  };

  goPrevPage = () => {
    const { activePageIndex, activeSubPageIndex, pages } = this.state;

    let updatedPageIndex = activePageIndex;
    let updatedSubPageIndex = activeSubPageIndex;

    const {
      canNavPrevPage,
      canNavPrevSubPage,
    } = this.updateCourseNavigability();

    if (canNavPrevSubPage) {
      updatedSubPageIndex--;
    } else if (canNavPrevPage) {
      updatedPageIndex--;
      updatedSubPageIndex = pages[updatedPageIndex].module
        ? pages[updatedPageIndex].subPages.length - 1
        : 0; // sub page last of group
    }

    this.setState(
      {
        activePageIndex: updatedPageIndex,
        activeSubPageIndex: updatedSubPageIndex,
      },
      this.savePageStatus
    );
  };

  renderMenuPages = () => {
    const { pages, activeSubPageIndex, activePageIndex } = this.state;

    return pages.map((page, indexPage) => {
      const hasSubPages = page.module && page.subPages?.length > 0;
      if (hasSubPages) {
        let allSubPagesVisited = true;

        const subPagesFormatted = page.subPages.map((subPage, indexSubPage) => {
          const menuItemActive =
            indexPage === activePageIndex &&
            indexSubPage === activeSubPageIndex;
          const subPageVisited = this.state.menuStatus.visited[subPage.id];
          allSubPagesVisited = !subPageVisited ? false : allSubPagesVisited;
          return (
            <a
              key={subPage.id}
              href={`#${subPage.id}`}
              className={`list-group-item list-group-item-action ${
                subPageVisited && "menuVisited"
              } ${menuItemActive === "menuActive"}`}
              onClick={this.navigateToPage.bind(this, indexPage, indexSubPage)}
            >
              {subPage.title}
            </a>
          );
        });

        return (
          <React.Fragment key={page.id}>
            <a
              href={`#${page.id}`}
              className={`${
                allSubPagesVisited && "menuVisited"
              } list-group-item list-group-item-dark divider`}
              data-toggle="collapse"
              role="button"
              aria-expanded={!this.state.menuStatus.collapsed[page.id]}
              aria-controls={page.id}
              onClick={this.setMenuStatus.bind(this, "collapsed", {
                [page.id]: !this.state.menuStatus.collapsed[page.id],
              })}
            >
              {page.title}
            </a>
            <div
              className={`collapse ${
                !this.state.menuStatus.collapsed[page.id] && "show"
              }`}
              id={page.id}
            >
              {subPagesFormatted}
            </div>
          </React.Fragment>
        );
      } else {
        const menuItemActive = indexPage === activePageIndex;
        return (
          <a
            key={page.id}
            href={`#${page.id}`}
            className={`list-group-item list-group-item-action ${
              this.state.menuStatus.visited[page.id] && "menuVisited"
            } ${menuItemActive === "menuActive"}`}
            onClick={this.navigateToPage.bind(this, indexPage, 0)}
          >
            {page.title}
          </a>
        );
      }
    });
  };

  renderMenu = () => {
    return (
      <div className={this.state.menuStatus.showing ? "menuShowing" : ""}>
        <button className="btn menuIcon"
          onClick={this.toggleMenu}
          data-toggle="tooltip"
          data-placement="left"
          title="Click for course menu"
          alt="Click for course menu"
        ></button>
        <div className="menuTray">
          <div className="container">
            <div className="ml-auto py-3 px-3">
              <button className="btn menuIcon menu-close"
                onClick={this.toggleMenu}
                title="Close Menu"
              ></button>
            </div>
            <div className="mt-4 w-100 d-inline-block">
              <div className="list-group">
                <div className="menuTitle" id="menuTitle">
                  <h5 className="list-group-item-heading">menu</h5>
                  <p>Click on a page below to jump to that page.</p>
                </div>
                <div id="menuPages">{this.renderMenuPages()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderNav = () => (
    <>
      <button
        type="button"
        className={`btn btn-nav circleBtn backBtn  ${
          this.state.prevDisabled ? "disabled" : ""
        }`}
        data-toggle="tooltip"
        data-placement="top"
        title="Click for next page"
        onClick={this.goPrevPage}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </button>

      <button
        type="button"
        className={`btn btn-nav circleBtn nextBtn ${
          this.state.nextDisabled ? "disabled" : ""
        }`}
        data-toggle="tooltip"
        data-placement="top"
        title="Click for next page"
        onClick={this.goNextPage}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </button>
    </>
  );

  render() {
    const { projectLoaded, pages } = this.state;
    return (
      <>
        {projectLoaded && pages ? (
          <div className="course-wrapper">
            <div className="course-inner">
              <div className="page-wrapper">{this.renderCurrentPage()}</div>
              {this.renderMenu()}
              {this.renderNav()}
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

export default ProjectPreview;
