import React from "react";

import fb from "../../api/Firebase";

import { Next, Camera } from "../graphics/Icons";

import { Modal, Alert } from "react-bootstrap";
import Logo from "../graphics/Logo";

import ApiConnector from "../../api/ApiConnector";

class ModalProjectEdit extends React.Component {
  state = {
    projectName: "",
    projectDesc: "",
    projectCoverPhoto: "",
    formSubmitting: false,
    cover: "",
    uploadPercentage: 0,
    draggingOver: false,
    uploadInProgress: false,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.projectName !== prevProps.projectName ||
      this.props.projectDesc !== prevProps.projectDesc ||
      this.props.projectCoverPhoto !== prevProps.projectCoverPhoto
    ) {
      const {
        projectName = "",
        projectDesc = "",
        projectCoverPhoto = "",
      } = this.props;

      this.setState({
        projectName,
        projectDesc,
        projectCoverPhoto,
      });
      console.log({ projectCoverPhoto });
    }
  }

  componentDidMount() {
    window.addEventListener(
      "dragover",
      (e) => {
        e = e || window.event;
        e.preventDefault();
        this.setState({
          draggingOver: true,
        });
      },
      false
    );

    window.addEventListener(
      "dragleave",
      (e) => {
        e = e || window.event;
        e.preventDefault();
        this.setState({
          draggingOver: false,
        });
      },
      false
    );
    window.addEventListener("drop", (e) => {
      e = e || window.event;
      e.preventDefault();
      console.log("Drop true");

      this.setState({
        draggingOver: false,
      });

      const droppedFiles = e.dataTransfer.files;

      this.uploadFile(droppedFiles[0]);
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value }, () => {
      // this.formValidations();
    });
  };
  uploadFile = (file) => {
    if (!file || !file.name) return false;

    const { userID } = this.props;

    this.setState({
      uploadPercentage: 0,
      uploadInProgress: true,
    });

    // Create storage ref
    const storageRef = fb
      .storage()
      .ref(`content/${userID}/assets/${file.name}`);

    // Upload file
    const task = storageRef.put(file);

    // Update progress bar
    task.on(
      "state_changed",
      (snapshot) => {
        const uploadPercentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          uploadPercentage,
        });
      },
      (err) => {
        console.warn(err);
        this.setState({
          uploadInProgress: false,
        });
      },
      () => {
        storageRef.getDownloadURL().then((url) => {
          this.setState({
            uploadInProgress: false,
            projectCoverPhoto: url,
          });
        });
      }
    );
  };

  formValidations = () => {
    const { projectName, projectDesc } = this.state;
    if (!projectName || !projectDesc) {
      this.setState({
        errorMessage: "Please fill out project name and description",
      });
      return false;
    }
    this.setState({
      errorMessage: null,
    });
    return true;
  };

  handleUpload = (e) => {
    e.preventDefault();

    console.log(e.target);
    // Get file
    const file = e.target.files[0];
    this.uploadFile(file);
  };

  createProject = () => {
    if (this.formValidations()) {
      const { projectName, projectDesc, projectCoverPhoto } = this.state;

      this.props.addProject({
        name: projectName,
        description: projectDesc,
        thumbnail: projectCoverPhoto,
      });
    }
  };

  render() {
    const {
      projectName,
      projectDesc,
      projectCoverPhoto,
      errorMessage,
      uploadInProgress,
      uploadPercentage,
      draggingOver,
    } = this.state;
    const { show, handleClose } = this.props;
    return (
      <Modal show={show} onHide={handleClose} className="modal-minimal">
        <div className="px-4">
          <Logo noText />
          <strong className="text-gray-mid">NEW PROJECT WIZARD</strong>
          <hr className="mt-1" />
        </div>
        <div className="px-5">
          <form className="d-flex form" onSubmit={this.handleSubmit}>
            <div className="formRow formRowLabelEntry mb-4">
              <div className="label">Project Name:</div>
              <input
                type="text"
                id="projectName"
                placeholder=""
                autoComplete="projectName"
                name="projectName"
                required
                value={projectName}
                onChange={this.handleChange}
              />
            </div>
            <div className="formRow formRowLabelEntry">
              <div className="label">Project Description:</div>
              <textarea
                type="text"
                id="projectDesc"
                placeholder=""
                autoComplete="projectDesc"
                name="projectDesc"
                required
                value={projectDesc}
                onChange={this.handleChange}
              />
            </div>
            {errorMessage && (
              <Alert variant="danger mt-3">{errorMessage}</Alert>
            )}
            {projectCoverPhoto ? (
              <div
                className="coverPhoto"
                style={{ backgroundImage: `url(${projectCoverPhoto})` }}
                onClick={() => {
                  this.fileInputElement.click();
                }}
              />
            ) : (
              <div
                className={`uploadPanel mt-4 mb-5 text-center ${
                  draggingOver ? "draggingOver" : ""
                }`}
                onClick={() => {
                  this.fileInputElement.click();
                }}
              >
                <Camera />
                <div className="label mt-2">Upload Cover Photo</div>
              </div>
            )}
            {uploadPercentage > 0 && uploadPercentage < 100 && (
              <progress
                value={uploadPercentage}
                max="100"
                id="uploader"
                className="uploadProgress mb-2"
              >
                {uploadPercentage}%
              </progress>
            )}
            <input
              type="file"
              value={""}
              id="fileButton"
              ref={(input) => (this.fileInputElement = input)}
              style={{ display: "none" }}
              onChange={this.handleUpload}
            />
          </form>
          {this.props.editingProject && (
            <div className="pb-4 display-flex justify-content-between">
              <button
                className="btn btn-outline btn-secondary"
                onClick={() => {
                  this.props.handleClose();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  console.log(this.props.project);
                  ApiConnector.updateProject(
                    this.props.userID,
                    this.props.project.projectID,
                    {
                      name: projectName,
                      description: projectDesc,
                      thumbnail: projectCoverPhoto,
                    }
                  );
                  this.props.handleClose();
                }}
              >
                Save Edits
              </button>
            </div>
          )}
        </div>
        {!this.props.editingProject && (
          <button
            className={`btn-quiet next-icon ${
              uploadInProgress ? "disabled" : ""
            }`}
            onClick={this.createProject}
          >
            <Next title="Create Project" />
          </button>
        )}
      </Modal>
    );
  }
}

export default ModalProjectEdit;
