export const paddingDefault = {
  x: 3,
  y: 3,
};

export const marginDefault = {
  x: 0,
  y: 0,
};

export const colorOptions = [
  "black",
  "dark",
  "secondary",
  "light",
  "white",
  "primary",
  "info",
  "success",
  "warning",
  "danger",
];

export const spacingOptions = ["0", "XS", "S", "M", "L", "XL"];
