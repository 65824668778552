import React from "react";

const Loading = (props) => {
  return (
    <div className={`loading ${props.absolute ? "loading-absolute" : ""}`}>
      <div className="loading-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
