import React from "react";
import * as reach from "@reach/router";
import { BASE_PATH } from "../../App";

const Link = ({ to = "", children, absolute, ...props }) => {
  if (!absolute && to[0] === "/") {
    to = BASE_PATH + to;
  }
  return (
    <reach.Link {...props} to={to}>
      {children}
    </reach.Link>
  );
};

export default Link;
