import React from "react";
import Link from "../navigation/Link";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { SubMenu, Image } from "../graphics/Icons";

const Project = React.memo((props) => {
  const { project, archived } = props;
  const handlePublish = () => props.onPublish(project);
  const handlePreview = () => props.onPreview(project);
  const handleArchive = () => props.onArchive(project);
  const handleDelete = () => props.onDelete(project);
  const handleRestore = () => props.onRestore(project);
  const handleEditInfo = () => props.onEditInfo(project);
  return (
    <div className="pos-rel">
      <Link
        key={project.projectID}
        className="projectWrapper"
        to={`/project/edit/${project.projectID}`}
      >
        <div className="project card">
          <div
            className="cardThumbnail"
            style={{ backgroundImage: `url(${project.thumbnail})` }}
          >
            {!project.thumbnail && (
              <div className="no-thumbnail">
                <Image title="no thumbnail" />
              </div>
            )}
          </div>
          <div className="cardDescription">
            <div className="projectDetails">
              <div className="projectName text-center">{project.name}</div>
              <hr />
              <div className="projectDescription truncate-overflow">
                {project.description}
              </div>
            </div>
          </div>
        </div>
      </Link>
      <div className="cardActions">
        <DropdownButton
          title={<SubMenu title="More Actions" />}
          className="btn-quiet"
          alignRight
        >
          {!archived && (
            <>
              <Dropdown.Item as="button" onClick={handlePublish}>
                Publish
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={handlePreview}>
                Preview
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={handleArchive}>
                Archive
              </Dropdown.Item>
            </>
          )}
          {archived && (
            <Dropdown.Item as="button" onClick={handleRestore}>
              Restore
            </Dropdown.Item>
          )}
          <Dropdown.Item as="button" onClick={handleEditInfo}>
            Edit Info
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            onClick={handleDelete}
            className="text-danger"
          >
            Delete
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  );
});

export default Project;
