import React from "react";
import ApiConnector from "../../api/ApiConnector";

import { navigate } from "@reach/router";
import { BASE_PATH } from "../../App";
import Link from "../navigation/Link";
import Toast from "react-bootstrap/Toast";
import Alert from "react-bootstrap/Alert";
import Logo from "../graphics/Logo";

const FORGOT_PASSWORD_MSG =
  "Please enter a valid email address to reset password";
const RESET_MESSAGE_SENT = "Password reset email has been sent";

class Login extends React.Component {
  state = {
    email: this.readUrlEmail(),
    password: "",
    errorMessage: null,
    formSubmitting: false,
    toastShow: false,
    toastMessage: RESET_MESSAGE_SENT,
    toastType: "alert-success",
  };

  readUrlEmail() {
    try {
      return atob(this.props.chosenEmail);
    } catch (e) {
      return "";
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value }, () => {
      // this.formValidations();
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      formSubmitting: true,
    });

    const { email, password } = this.state;

    try {
      await ApiConnector.signIn(email, password);
      navigate(BASE_PATH + "/");
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        navigate(BASE_PATH + `/register/${btoa(email)}`);
      }
      if (err.message !== null) {
        this.setState({ errorMessage: err.message, formSubmitting: false });
      } else {
        this.clearErrorMessage();
      }
    }
  };

  clearErrorMessage() {
    this.setState({ errorMessage: null, formSubmitting: false });
  }

  async handleForgotPassword() {
    this.clearErrorMessage();
    const { email } = this.state;

    if (!email || email.indexOf("@") === -1 || email.indexOf(".") === -1) {
      this.setState({
        errorMessage: FORGOT_PASSWORD_MSG,
        formSubmitting: false,
      });
      return false;
    }

    try {
      await ApiConnector.resetPassword(email);
      this.setState({
        toastMessage: RESET_MESSAGE_SENT,
        toastType: "alert-success",
        toastShow: true,
      });
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        navigate(BASE_PATH + `/register/${btoa(email)}`);
      }
      this.setState({
        errorMessage: FORGOT_PASSWORD_MSG,
        formSubmitting: false,
      });
    }
  }

  render() {
    const { formSubmitting, email, password, errorMessage } = this.state;
    return (
      <div className="loginContainer">
        <Logo />
        <form className="d-flex card" onSubmit={this.handleSubmit}>
          <div className="formRow formRowLabelEntry">
            <div className="label">Email:</div>
            <input
              type="email"
              id="email"
              placeholder="Please enter a valid email"
              autoComplete="email"
              name="email"
              required
              value={email}
              onChange={this.handleChange}
            />
          </div>
          <div className="formRow formRowLabelEntry">
            <div className="label">Password:</div>
            <input
              type="password"
              id="password"
              placeholder="Password must be at least 6 characters"
              autoComplete="current-password"
              name="password"
              required
              value={password}
              onChange={this.handleChange}
            />
          </div>
          <div>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          </div>
          <div className="formRow formRowButtons dualButtons">
            <button
              className={`button bright solid ${formSubmitting && "disabled"}`}
              type="submit"
            >
              Login
            </button>
            <Link className="button bright" to={`/register/${btoa(email)}`}>
              Register
            </Link>
          </div>
          <div className="align-self-bottom">
            <button
              className={`button quiet b-none ${formSubmitting && "disabled"}`}
              onClick={(e) => {
                e.preventDefault();
                this.handleForgotPassword();
              }}
            >
              Forgot Password
            </button>
          </div>
        </form>
        <div className="fixed-toast-container">
          <Toast
            show={this.state.toastShow}
            autohide
            onClose={() => {
              this.setState({ toastShow: false });
            }}
          >
            <div
              className={`alert ${this.state.toastType}`}
              style={{ marginBottom: 0 }}
            >
              <strong>{this.state.toastMessage}</strong>
            </div>
          </Toast>
        </div>
      </div>
    );
  }
}

export default Login;
