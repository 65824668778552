import { paddingDefault, marginDefault } from "./defaultProperties";

/**
 *
 * @param {Array} cols - columns containing content
 * @returns {Array} of classnames
 */
function getColumnSpecificClassNames(cols = []) {
  switch (cols.length) {
    case 2:
      return ["flex-wrap", "flex-sm-nowrap"];
    case 3:
      return ["flex-wrap", "flex-sm-nowrap", "justify-content-around"];
    default:
      return [];
  }
}

/**
 *
 * @param {Object} section - section object
 * @returns {String} classNames
 */
export function getSectionClassName(section) {
  const { cols = [], config = {} } = section;
  const defaultClasses = ["d-flex", "flex-row"]; // base classes applied to all sections / "rows"
  const defaultBg = ["bg-light"];
  const defaultColor = ["text-dark"];
  const defaultPadding = [`px-${paddingDefault.x}`, `py-${paddingDefault.y}`];
  const defaultMargin = [`mx-${marginDefault.x}`, `my-${marginDefault.y}`];

  const customPadding =
    typeof config.padding === "object"
      ? [
          `px-${config.padding.x || paddingDefault.x}`,
          `py-${config.padding.y || paddingDefault.y}`,
        ]
      : [`p-${config.padding}`];

  const customMargin =
    typeof config.margin === "object"
      ? [
          `mx-${config.margin.x || marginDefault.x}`,
          `my-${config.margin.y || marginDefault.y}`,
        ]
      : [`m-${config.margin}`];

  return [
    ...defaultClasses,
    ...(typeof config.bg !== "undefined" ? [config.bg] : defaultBg),
    ...(typeof config.color !== "undefined" ? [config.color] : defaultColor),
    ...(typeof config.padding !== "undefined" ? customPadding : defaultPadding),
    ...(typeof config.margin !== "undefined" ? customMargin : defaultMargin),
    ...getColumnSpecificClassNames(cols),
  ].join(" ");
}
