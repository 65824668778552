import React from 'react';

export default function FillInTheBlank({ data = {}, updateData = () => { } }) {

    const {
        before,
        answer,
        after,
        placement,
        placeholder,
        instructions
    } = data;

    const heightAdjustElement = (element) => {
        element.style.height = "inherit";
        element.style.height = `${element.scrollHeight + 2}px`;
    }

    const dataUpdate = (e) => {
        heightAdjustElement(e.target);
        updateData({ key: e.target.name, val: e.target.value });

        // In case you have a limitation
        // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    };

    const placementButtons = [
        {
            value: "start",
            label: "Start"
        },
        {
            value: "middle",
            label: "Middle"
        },
        {
            value: "end",
            label: "End"
        }
    ];

    const adjustDynamicHeightTextAreas = () => {
        ['before', 'answer', 'after', 'placeholder', 'instructions'].map(text => {
            document.querySelector(`[name="${text}"]`) && heightAdjustElement(document.querySelector(`[name="${text}"]`));
        });
    }

    React.useEffect(() => {
        adjustDynamicHeightTextAreas();
        window.addEventListener('resize', adjustDynamicHeightTextAreas)
        return () => window.removeEventListener('resize', adjustDynamicHeightTextAreas);
    }, []);

    return (
        <div className="fill-in-blank mt-2 mb-4">
            <div className="row">
                <label className=" col pb-2">
                    <strong className="py-4">Question / Instructions for Fill-in the blank</strong>
                    <textarea
                        className="bg-light form-control"
                        value={instructions}
                        name="instructions"
                        onChange={dataUpdate}
                        placeholder="Enter instructions for fill in blank here"
                    />
                </label>
            </div>
            <div className="row mt-2 mb-4">
                <div className="col my-1">
                    <strong className="d-block pb-1">Fill-blank-position</strong>
                    <div
                        className="btn-group mx-auto"
                        role="group"
                        aria-label="Fill-in-blank placement"
                    >
                        {placementButtons.map(({ value: val, label }) => (
                            <button
                                onClick={() => {
                                    updateData({ key: 'placement', val });
                                    setTimeout(() => {
                                        adjustDynamicHeightTextAreas();
                                    }, 0);
                                }}
                                name={val}
                                key={val}
                                type="button"
                                className={`btn ${placement === val ? "btn-dark" : "btn-outline-dark"
                                    }`}
                            >
                                {label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row">
                <label className="col"><strong>Fill-in-blank question content</strong></label>
            </div>
            <div className="row">
                {placement !== "start" && (
                    <label className=" col">
                        <textarea
                            placeholder="Text before"
                            rows="1"
                            className="bg-light form-control"
                            value={before}
                            name="before"
                            onChange={dataUpdate}
                        />
                    </label>
                )}
                <label className=" col-auto">
                    <textarea
                        rows="1"
                        className="bg-light form-control"
                        placeholder="Fill-in answer"
                        value={answer}
                        name="answer"
                        onChange={dataUpdate}
                    />
                    <textarea
                        rows="1"
                        className="bg-light form-control mt-1"
                        placeholder="Fill-in placeholder"
                        value={placeholder}
                        name="placeholder"
                        onChange={dataUpdate}
                    />
                    <hr className="mt-1 bg-dark" />
                </label>
                {placement !== "end" && (
                    <label className=" col">
                        <textarea
                            rows="1"
                            className="bg-light form-control"
                            placeholder="Text after"
                            value={after}
                            name="after"
                            onChange={dataUpdate}
                        />
                    </label>
                )}
            </div>
        </div>
    );
};