import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB_qAbnsR3kHh-Nw9nsrZJ8vWm2sS9Yb3Y",
  authDomain: "learning-dojo-author.firebaseapp.com",
  databaseURL: "https://learning-dojo-author.firebaseio.com",
  projectId: "learning-dojo-author",
  storageBucket: "learning-dojo-author.appspot.com",
  messagingSenderId: "155714167410",
  appId: "1:155714167410:web:2e25740a94ddde7957d8eb",
  measurementId: "G-LVDHGN14SJ",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();

const fb = firebase;

export default fb;
