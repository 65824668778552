/* eslint-disable no-loop-func */
import React from "react";
import fb from "../../api/Firebase";
import { navigate } from "@reach/router";
import Link from "../navigation/Link";
import { BASE_PATH } from "../../App";
import {
  BackHome,
  PhonePreviewVert,
  TabletPreviewVert,
  PhonePreviewHoriz,
  TabletPreviewHoriz,
  DesktopPreview,
} from "../graphics/Icons";
import ToastMinimal from "../errorHandling/ToastMinimal";

const PREVIEW_CLASS_NAME = "preview-body";
const PREVIEW_SIZES = [
  [{ label: "DESKTOP", icon: <DesktopPreview />, x: 1920, y: 1080 }],
  [
    { label: "TABLET", icon: <TabletPreviewVert />, x: 768, y: 992 },
    { label: "TABLET", icon: <TabletPreviewHoriz />, x: 1024, y: 684 },
  ],
  [
    { label: "PHONE", icon: <PhonePreviewVert />, x: 414, y: 736 },
    { label: "PHONE", icon: <PhonePreviewHoriz />, x: 746, y: 414 },
  ],
];

function enableResizeLogic(element, reactComponent) {
  const resizers = element.querySelectorAll(".resizer");
  const MIN_WIDTH = 320;
  let original_width = 0;
  let original_height = 0;
  let original_mouse_x = 0;
  let original_mouse_y = 0;
  for (let i = 0; i < resizers.length; i++) {
    const currentResizer = resizers[i];
    currentResizer.addEventListener("mousedown", (e) => {
      reactComponent.setState({
        resizing: true,
      });
      e.preventDefault();
      original_width = parseFloat(
        getComputedStyle(element, null)
          .getPropertyValue("width")
          .replace("px", "")
      );
      original_height = parseFloat(
        getComputedStyle(element, null)
          .getPropertyValue("height")
          .replace("px", "")
      );
      original_mouse_x = e.pageX;
      original_mouse_y = e.pageY;
      window.addEventListener("mousemove", resize);
      window.addEventListener("mouseup", stopResize);
    });

    function resize(e) {
      const max_width = window?.innerWidth - 40; // Dynamic
      if (currentResizer.classList.contains("bottom-resize-tab")) {
        const height = original_height + (e.pageY - original_mouse_y);
        if (height > MIN_WIDTH) {
          element.style.height =
            Math.min(height, window.innerHeight - 120) + "px";
          element.style.top = "100px";
        }
      } else if (currentResizer.classList.contains("left-resize-tab")) {
        const width = original_width - (e.pageX - original_mouse_x);
        if (width > MIN_WIDTH) {
          element.style.width =
            Math.max(
              MIN_WIDTH,
              Math.min(width - (e.pageX - original_mouse_x), max_width)
            ) + "px";
        }
      } else if (currentResizer.classList.contains("right-resize-tab")) {
        const width = original_width + (e.pageX - original_mouse_x);
        if (width > MIN_WIDTH) {
          element.style.width =
            Math.max(
              MIN_WIDTH,
              Math.min(width + (e.pageX - original_mouse_x), max_width)
            ) + "px";
        }
      }
      reactComponent.setState({
        previewWidth: element.getBoundingClientRect().width,
        previewHeight: element.getBoundingClientRect().height,
        previewItem: -1,
        toastShow: false,
      });
      document.activeElement.blur();
    }

    function stopResize() {
      reactComponent.setState({
        resizing: false,
      });
      window.removeEventListener("mousemove", resize);
    }
  }
}

class ProjectPresenter extends React.Component {
  state = {
    name: "",
    scorm: "",
    pages: [],
    projectLoaded: false,
    previewWidth: 0,
    previewHeight: 0,
    previewItem: -1,
    resizing: false,
    toastShow: false,
    toastVariant: null,
    toastMsg: "",
  };

  constructor(props) {
    super(props);
    this.resizer = React.createRef();
  }

  componentDidMount() {
    document.querySelector("body").classList.add(PREVIEW_CLASS_NAME);
    document.querySelector("body").classList.remove(window.ld_author_main);
    const { userID, projectID } = this.props;
    const ref = fb.database().ref(`projects/${userID}/${projectID}`);

    ref.on("value", (snapshot) => {
      let project = snapshot.val();
      if (project) {
        const { name, scorm, description, pages } = project;
        this.setState({
          name,
          scorm,
          pages,
          description,
        });
      } else {
        navigate("/404");
      }
      this.setState({
        projectLoaded: true,
      });
    });

    window.addEventListener("resize", () => {
      this.getDimensionsFromCurrent();
    });

    enableResizeLogic(this.resizer.current, this);
    this.getDimensionsFromCurrent();
  }

  componentWillUnmount() {
    document.querySelector("body").classList.remove(PREVIEW_CLASS_NAME);
    document.querySelector("body").classList.add(window.ld_author_main);
    window.removeEventListener("resize", () => {
      this.getDimensionsFromCurrent();
    });
  }

  clearPreviousResizeErrors = () => {
    // reset errors
    this.setState({
      toastShow: false,
      toastMsg: "",
      toastVariant: null,
    });
    if (this.checkDimensionTimeout) {
      clearTimeout(this.checkDimensionTimeout);
      this.checkDimensionTimeout = null;
    }
  };

  resizeContainer = (x, y, previewItem) => {
    this.clearPreviousResizeErrors();

    const element = this.resizer.current;
    element.style.width = `${x}px`;
    element.style.height = `${y}px`;
    this.setState(
      {
        previewWidth: x,
        previewHeight: y,
        previewItem,
      },
      () => {
        this.checkDimensionTimeout = setTimeout(() => {
          if (
            x !== element.getBoundingClientRect().width ||
            y !== element.getBoundingClientRect().height
          ) {
            this.setState({
              previewWidth: Math.floor(element.getBoundingClientRect().width),
              previewHeight: Math.floor(element.getBoundingClientRect().height),
              previewItem: -1,
              toastShow: true,
              toastMsg:
                "Could not resize to chosen dimensions, please try increasing browser size",
              toastVariant: "danger",
            });
            document.activeElement.blur();
          }
        }, 1000);
      }
    );
  };

  orientationSwitch = () => {};

  getDimensionsFromCurrent = () => {
    const element = this.resizer.current;
    if (!!element) {
      this.setState({
        previewWidth: Math.floor(element.getBoundingClientRect().width),
        previewHeight: Math.floor(element.getBoundingClientRect().height),
      });
    }
  };

  renderPreviewSelectionButtons = () => {
    let globalIndex = 0;
    let predefinedRegionActive = false;

    const previewSelectionButtons = PREVIEW_SIZES.map((group, index) => {
      let groupContainsActive = false;
      const innerGroupContent = group.map((previewSize) => {
        globalIndex++;
        const itemActive = this.state.previewItem === globalIndex;
        groupContainsActive = itemActive || groupContainsActive;
        predefinedRegionActive = itemActive || predefinedRegionActive;
        const { x, y } = previewSize;
        return (
          <button
            key={`${previewSize.label}-${index}-${x}-${y}`}
            className={`${
              itemActive ? "preview-active" : ""
            } icon-match preview-btn`}
            onClick={this.resizeContainer.bind(this, x, y, globalIndex)}
          >
            {previewSize.icon}
          </button>
        );
      });
      return (
        <div
          className={`preview-group-outer px-3 py-1 ${
            groupContainsActive && "group-contains-active"
          }`}
          key={`${group[0].label}-${index}`}
        >
          <div className={`preview-group`}>{innerGroupContent}</div>
          <small className="label">{group[0].label}</small>
        </div>
      );
    });

    return (
      <>
        {previewSelectionButtons}
        <div
          className={`preview-group-outer px-3 py-1 ${
            !predefinedRegionActive && "group-contains-active"
          }`}
        >
          <div
            className={`${
              !predefinedRegionActive ? "preview-active" : ""
            } preview-group`}
          >
            <button
              className={`inherit-color ${
                !predefinedRegionActive ? "preview-active" : ""
              } icon-match preview-btn pt-1 pb-2`}
              onClick={this.resizeContainer.bind(
                this,
                window.innerWidth - 200,
                window.innerHeight - 200,
                -1
              )}
            >
              <strong>{this.state.previewWidth?.toFixed(0)}</strong>{" "}
              <small>X</small>{" "}
              <strong>{this.state.previewHeight?.toFixed(0)}</strong>
            </button>
          </div>
          <small className="label">CUSTOM</small>
        </div>
      </>
    );
  };

  render() {
    const { resizing, previewWidth, previewHeight } = this.state;
    const { userID, projectID } = this.props;
    return (
      <div className="dojo-editor">
        <div className="d-flex">
          <div>
            <div className="bg-light text-dark shadow-sm text-center px-4 py-2 menu-preview-top-nav">
              <Link to={`/project/edit/${projectID}`} className="btn-home">
                <BackHome />
                Back to Editor
              </Link>
            </div>
          </div>
          <div className="flex-1 text-center py-3">
            {this.renderPreviewSelectionButtons()}
          </div>
          <div className="menu-preview-top-nav"></div>
        </div>
        <div
          className={`resizable ${resizing ? "resizing" : ""}`}
          ref={this.resizer}
        >
          <div className="resizers">
            <div className="resizer right-resize-tab"></div>
            <div className="resizer left-resize-tab"></div>
            <div className="resizer horizontal bottom-resize-tab"></div>
          </div>
          <div className="content">
            <div className={`preview-harnass`}>
              {!resizing && (
                <iframe
                  src={`${BASE_PATH}/preview/${userID}/${projectID}`}
                  title="preview"
                  name="preview"
                ></iframe>
              )}
              {resizing && (
                <>
                  <p>
                    Width: <strong>{previewWidth?.toFixed(0)}px</strong>
                  </p>
                  <p>
                    Height: <strong>{previewHeight?.toFixed(0)}px</strong>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {this.state.toastShow && (
          <ToastMinimal
            initShow={this.state.toastShow}
            msg={this.state.toastMsg}
            onClose={() => {
              setTimeout(() => {
                this.setState({ toastShow: false });
              }, 500);
            }}
            variant={this.state.toastVariant}
            position="offset"
          />
        )}
      </div>
    );
  }
}

export default ProjectPresenter;
