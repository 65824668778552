import { ADVANCED_TYPES } from './advancedTemplateDefinitions';

export const templateLibrary = {
  textBlockCourseTitle: {
    htmlTemplate: `<div class="d-flex flex-column align-items-center justify-content-around colorBar w-100">
      <h1>learning components</h1>
      <h2>a guide for how learning components are styled.</h2>
    </div>`,
  },
  textBlockText: {
    htmlTemplate: `<div class="d-flex flex-column p-3">
    <h5>Example Text</h5>
    <p>Bacon ipsum dolor amet flank ball tip filet mignon jerky andouille capicola short ribs boudin beef bacon pork chop. Alcatra tongue andouille, biltong t-bone pancetta shoulder venison shank.
    </p>
  </div>`,
  },
  imageBlockTextImageRight: {
    htmlTemplate: `<div class="d-flex flex-column">
  <div class="d-flex flex-row w-100 px-5 py-5 justify-content-around flex-wrap flex-sm-nowrap">
  <div class="pr-5 d-flex flex-column w-60">
    <h5>Example Text</h5>
    <p>Bacon ipsum dolor amet flank ball tip filet mignon jerky andouille capicola short ribs boudin beef bacon pork chop. Alcatra tongue andouille, biltong t-bone pancetta shoulder venison shank.
    </p>
  </div>
  <div class="d-flex flex-column w-40">
  <img src="http://res.cloudinary.com/d3/image/upload/c_pad,g_center,h_200,q_auto:eco,w_200/bootstrap-logo_u3c8dx.jpg" />
  </div></div>
</div>`,
  },
  imageBlockTextImageLeft: {
    htmlTemplate: `<div class="d-flex flex-column">
<div class="d-flex flex-row w-100 px-5 py-5 justify-content-around flex-wrap flex-sm-nowrap">
<div class="d-flex flex-column w-40">
<img src="http://res.cloudinary.com/d3/image/upload/c_pad,g_center,h_200,q_auto:eco,w_200/bootstrap-logo_u3c8dx.jpg" />
</div>
<div class="pl-5 d-flex flex-column w-60">
  <h5>Example Text</h5>
  <p>Bacon ipsum dolor amet flank ball tip filet mignon jerky andouille capicola short ribs boudin beef bacon pork chop. Alcatra tongue andouille, biltong t-bone pancetta shoulder venison shank.
  </p>
</div></div>
</div>`,
  },
  imageBlockCard1: {
    htmlTemplate: `
  <div class="card m-3">
    <img src="http://res.cloudinary.com/d3/image/upload/c_pad,g_center,h_200,q_auto:eco,w_200/bootstrap-logo_u3c8dx.jpg" class="card-img-top" />
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.
      </p>
      <a href="#" class="btn btn-secondary">view more</a>
    </div>
  </div>`,
  },
  imageBlockCard2: {
    htmlTemplate: `<div class="card m-3 shadow">
      <img src="https://firebasestorage.googleapis.com/v0/b/learning-dojo-author.appspot.com/o/content%2FNoWH3atUp9f8zx2H9hSV6ncdwYf2%2Fassets%2Fbiz.jpg?alt=media&token=543fc917-0995-46b6-9825-74bf465f89b5" class="card-img-top" />
      <div class="card-body">
        <h5 class="card-title">Jeff Batt</h5>
        <p class="card-text">CEO - Consultant</p>
        <div class="d-flex flex-row justify-content-start">
          <button type="button" class="btn btn-outline-secondary btn-circle btn-md mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" fill="#798189"/></svg></button> 
          <button type="button" class="btn btn-outline-secondary btn-circle btn-md mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" fill="#798189"/></svg></button> 
          <button type="button" class="btn btn-outline-secondary btn-circle btn-md mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" fill="#798189"/></svg></button> 
        </div>
      </div>
    </div>`,
  },
  imageBlockCard3: {
    htmlTemplate: `<div class="card card-custom bg-white border-white border-0">
      <div class="card-custom-img" style="background-image: url(http://res.cloudinary.com/d3/image/upload/c_scale,q_auto:good,w_1110/trianglify-v1-cs85g_cc5d2i.jpg);"></div>
      <div class="card-custom-avatar">
        <img class="img-fluid" src="http://res.cloudinary.com/d3/image/upload/c_pad,g_center,h_200,q_auto:eco,w_200/bootstrap-logo_u3c8dx.jpg" alt="Avatar" />
      </div>
      <div class="card-body" style="overflow-y: auto">
        <h4 class="card-title">Card title</h4>
        <p class="card-text">Card has minimum height set but will expand if more space is needed for card body content. You can use Bootstrap <a href="https://getbootstrap.com/docs/4.0/components/card/#card-decks" target="_blank">card-decks</a> to align multiple cards nicely in a row.</p>
      </div>
    </div>`,
  },
  imageBlockCard4: {
    htmlTemplate: `<div class="d-flex flex-row my-4 py-5 px-5 justify-content-start">
        <div class="card mb-3 mb-sm-0 mr-0 mr-sm-2 p-3 shadow">
          <img src="https://firebasestorage.googleapis.com/v0/b/learning-dojo-author.appspot.com/o/content%2FNoWH3atUp9f8zx2H9hSV6ncdwYf2%2Fassets%2Fshoe.png?alt=media&token=d7e9e196-f555-4024-ad47-97a7cbe13d25" class="card-img-top w-25 align-self-center" />
          <div class="card-body">
            <h5 class="card-title">Show Sample</h5>
            <p class="card-text">Bacon ipsum dolor amet flank ball tip filet mignon jerky andouille capicola short ribs boudin beef bacon pork chop. Alcatra tongue andouille, biltong t-bone pancetta shoulder venison shank.</p>
            <div class="d-flex flex-row justify-content-start">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" fill="#EEB868"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" fill="#EEB868"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" fill="#EEB868"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" fill="#EEB868"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 5.173l2.335 4.817 5.305.732-3.861 3.71.942 5.27-4.721-2.524v-12.005zm0-4.586l-3.668 7.568-8.332 1.151 6.064 5.828-1.48 8.279 7.416-3.967 7.416 3.966-1.48-8.279 6.064-5.827-8.332-1.15-3.668-7.569z" fill="#EEB868"/></svg>
            </div>
          </div>
        </div>`,
  },
  textBlockListGroup1: {
    htmlTemplate: `  <div class="flex-column py-5 px-3">
  <div class="list-group">
    <a href="#" class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">List group item heading</h5>
        <small>3 days ago</small>
      </div>
      <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
      <small>Donec id elit non mi porta.</small>
    </a>
    <a href="#" class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">List group item heading</h5>
        <small class="text-muted">3 days ago</small>
      </div>
      <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
      <small class="text-muted">Donec id elit non mi porta.</small>
    </a>
    <a href="#" class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">List group item heading</h5>
        <small class="text-muted">3 days ago</small>
      </div>
      <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
      <small class="text-muted">Donec id elit non mi porta.</small>
    </a>
  </div>
  </div>`,
  },
  textBlockListGroup2: {
    htmlTemplate: `
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center">
        Cras justo odio
        <span class="badge badge-primary badge-pill">14</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center">
        Dapibus ac facilisis in
        <span class="badge badge-primary badge-pill">2</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center">
        Morbi leo risus
        <span class="badge badge-primary badge-pill">1</span>
      </li>
    </ul> 
  `,
  },
  /* TODO - Needs to be JSON / Advanced template */
  textBlockCollapse: {
    advancedTemplate: true,
    htmlTemplate: `    <a class="btn btn-primary" data-toggle="collapse" href="#collapseOne" role="button" aria-expanded="false"
      aria-controls="collapseExample">show content</a>
    <div class="collapse mt-3 show" id="collapseOne">
      <div class="card card-body">
        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
      </div>
    </div>`,
  },
  /* TODO - Needs to be JSON / Advanced template */
  textBlockPopOver: {
    advancedTemplate: true,
    htmlTemplate: `
    <button type="button" class="btn btn-lg btn-danger" data-toggle="popover" title="Popover title"
      data-content="And here's some amazing content. It's very engaging. Right?" data-placement="top">
      Click to toggle popover
    </button>
  `,
  },
  textBlockUnorderedList: {
    htmlTemplate: `
  <ul class="circle-unordered-list mt-4">
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak.</li>
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak.</li>
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak.</li>
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak.</li>
    </ul>
    `,
  },
  textBlockNumberList1: {
    htmlTemplate: `
  <ol class="circle-numbered-list mt-4">
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak. Cow tenderloin turducken, ham jowl pork chop fatback ham hock sausage shoulder short loin ball tip alcatra.</li>
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak. Cow tenderloin turducken, ham jowl pork chop fatback ham hock sausage shoulder short loin ball tip alcatra.</li>
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak. Cow tenderloin turducken, ham jowl pork chop fatback ham hock sausage shoulder short loin ball tip alcatra.</li>
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak. Cow tenderloin turducken, ham jowl pork chop fatback ham hock sausage shoulder short loin ball tip alcatra.</li>
    </ol>
  `,
  },
  textBlockNumberList2: {
    htmlTemplate: `
  <ol class="square-numbered-list mt-4">
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak. Cow tenderloin turducken, ham jowl pork chop fatback ham hock sausage shoulder short loin ball tip alcatra.</li>
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak. Cow tenderloin turducken, ham jowl pork chop fatback ham hock sausage shoulder short loin ball tip alcatra.</li>
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak. Cow tenderloin turducken, ham jowl pork chop fatback ham hock sausage shoulder short loin ball tip alcatra.</li>
      <li>Bacon ipsum dolor amet pancetta tenderloin sausage shankle. Shank cow ground round strip steak. Cow tenderloin turducken, ham jowl pork chop fatback ham hock sausage shoulder short loin ball tip alcatra.</li>
    </ol>
  `,
  },
  /* TODO - Needs to be JSON / Advanced template */
  textBlockModal: {
    advancedTemplate: true,
    htmlTemplate: `
    <button type="button" class="btn btn-primary btn-lg" data-toggle="modal" data-target="#exampleModal">View Modal</button>
  <div class="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Bacon ipsum dolor amet porchetta frankfurter tail short ribs. Cupim tongue drumstick beef corned beef pork filet mignon shankle. Venison drumstick ham hock cupim tenderloin t-bone turkey ribeye. Shank pork chop swine prosciutto jowl cow.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  `,
  },
  imageBlockImageBannerLeft: {
    htmlTemplate: `  <div class="d-flex flex-column imageBanner tranBlack align-items-start shadow banner1" style="background-image:linear-gradient(to right, rgba(197, 27, 35, 0.6), rgba(237, 122, 28, 0.6)), url(https://firebasestorage.googleapis.com/v0/b/learning-dojo-author.appspot.com/o/content%2FNoWH3atUp9f8zx2H9hSV6ncdwYf2%2Fassets%2Fbeach.jpg?alt=media&token=f26fe830-b98d-4b2d-a8d5-2db87e0b7cdf)">
    <h1 className="text=white">image banner</h1>
    <h2>This is the subtitle for this section.</h2>
  </div>`,
  },
  imageBlockImageBannerRight: {
    htmlTemplate: `  <div class="d-flex flex-column imageBanner tranBlack align-items-end shadow banner1" style="background-image:linear-gradient(to right, rgba(48, 63, 104, 0.7), rgba(229, 90, 79, 0.6)), url(https://firebasestorage.googleapis.com/v0/b/learning-dojo-author.appspot.com/o/content%2FNoWH3atUp9f8zx2H9hSV6ncdwYf2%2Fassets%2Fbeach2.jpg?alt=media&token=bf53e331-b34b-4d6c-aff0-6e50d0f7786c)">
    <h1 className="text=white">image banner</h1>
    <h2>This is the subtitle for this section.</h2>
  </div>`,
  },
  imageBlockImageCarousel: {
    advancedTemplate: true,
    htmlTemplate: `
 <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="http://res.cloudinary.com/d3/image/upload/c_scale,q_auto:good,w_1110/trianglify-v1-cs85g_cc5d2i.jpg" class="d-block w-100" />
          <div class="carousel-caption d-none d-md-block text-shadow">
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="http://res.cloudinary.com/d3/image/upload/c_scale,q_auto:good,w_1110/trianglify-v1-cs85g_cc5d2i.jpg" class="d-block w-100" />
          <div class="carousel-caption d-none d-md-block text-shadow">
            <h5>Second slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="http://res.cloudinary.com/d3/image/upload/c_scale,q_auto:good,w_1110/trianglify-v1-cs85g_cc5d2i.jpg" class="d-block w-100" />
          <div class="carousel-caption d-none d-md-block text-shadow">
            <h5>Third slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    `,
  },
  elementBlockProgress: {
    advancedTemplate: true,
    htmlTemplate: `
<div class="progress">
      <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0"
        aria-valuemax="100">
        25%
      </div>
    </div>
    `,
  },
  elementBlockQuote1: {
    htmlTemplate: `<div class="d-flex flex-column quote1 align-items-center my-5">
    <img src="https://firebasestorage.googleapis.com/v0/b/learning-dojo-author.appspot.com/o/content%2FNoWH3atUp9f8zx2H9hSV6ncdwYf2%2Fassets%2Fjeff.jpg?alt=media&token=a10f46f7-73e5-42aa-9af8-ddaffeb7d1c4" class="quote1Img rounded-circle shadow">
    <p class="mx-5 my-3">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in convallis ex. Donec bibendum semper tincidunt. Nullam elit ipsum, varius sit amet nisi non, euismod faucibus magna. Ut at lorem quis odio vulputate sagittis sed eget risus. Pellentesque at eros justo."</p>
    <p>- Jeff Batt</p>    
    </div>`,
  },
  elementBlockQuote2: {
    htmlTemplate: `<div class="container d-flex flex-row quote2 align-items-center my-5">
    <img src="https://firebasestorage.googleapis.com/v0/b/learning-dojo-author.appspot.com/o/content%2FNoWH3atUp9f8zx2H9hSV6ncdwYf2%2Fassets%2Fjeff.jpg?alt=media&token=a10f46f7-73e5-42aa-9af8-ddaffeb7d1c4" class="quote2Img rounded mx-4">
    <div class="d-flex flex-column">
      <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in convallis ex. Donec bibendum semper tincidunt. Nullam elit ipsum, varius sit amet nisi non, euismod faucibus magna. Ut at lorem quis odio vulputate sagittis sed eget risus. Pellentesque at eros justo."</p>
      <p>- Jeff Batt</p>
    </div>
    </div>`,
  },
  elementBlockQuote3: {
    htmlTemplate: `<div class="d-flex flex-row quote3 align-items-center lightColor p-5 flex-wrap flex-sm-nowrap">
    <img src="https://firebasestorage.googleapis.com/v0/b/learning-dojo-author.appspot.com/o/content%2FNoWH3atUp9f8zx2H9hSV6ncdwYf2%2Fassets%2Fjeff.jpg?alt=media&token=a10f46f7-73e5-42aa-9af8-ddaffeb7d1c4" class="quote3Img shadow rounded-circle mx-auto mx-sm-4">
    <div class="d-flex flex-column">
      <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in convallis ex. Donec bibendum semper tincidunt. Nullam elit ipsum, varius sit amet nisi non, euismod faucibus magna. Ut at lorem quis odio vulputate sagittis sed eget risus. Pellentesque at eros justo."</p>
      <p>- Jeff Batt</p>
    </div>   
    </div>`,
  },
  elementBlockQuote4: {
    htmlTemplate: `<div class="d-flex flex-row grayBack px-5 py-5 my-4 py-3 justify-content-around">
    <blockquote class="blockquote blockquote-custom bg-white p-5 shadow rounded">
      <div class="blockquote-custom-icon bg-info shadow-sm"><i class="fa fa-quote-left text-white"></i></div>
      <p class="mb-0 mt-2 font-italic">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo <a href="#" class="text-info">@consequat</a>."</p>
      <footer class="blockquote-footer pt-4 mt-4 border-top">Someone famous in
          <cite title="Source Title">Source Title</cite>
      </footer>
  </blockquote>
    </div>`,
  },
  interactiveBlockAccordion: {
    advancedTemplate: true,
    advancedTemplateReady: true,
    jsonTemplate: {
      type: ADVANCED_TYPES.ACCORDION,
      sections: [
        {
          title: "Item 1",
          content: "<b>Hello</b> World",
        },
        {
          title: "Item 2",
          content: "<b>Hello</b> World",
        },
        {
          title: "Item 3",
          content: "<em>Hello</em> World",
        },
      ],
    },
    htmlTemplate: '',
  },
  quizBlockMultipleChoice: {
    advancedTemplate: true,
    advancedTemplateReady: true,
    jsonTemplate: {
      type: ADVANCED_TYPES.MULTIPLE_CHOICE,
      question: "What is the color of the sky?",
      choices: [
        {
          correct: true,
          content: "Blue",
        },
        {
          correct: false,
          content: "Green",
        },
        {
          correct: false,
          content: "Red",
        },
        {
          correct: false,
          content: "Yellow",
        },
      ],
      feedback: {
        correct: 'You answered that question correctly!',
        incorrect: 'Sorry, you answered that question incorrectly.',
      }
    },
    htmlTemplate: '',
  },
  quizBlockMultipleSelect: {
    advancedTemplate: true,
    advancedTemplateReady: true,
    jsonTemplate: {
      type: ADVANCED_TYPES.MULTIPLE_SELECT,
      question: "Select all the options that apply",
      choices: [
        {
          correct: true,
          content: "Blue",
        },
        {
          correct: true,
          content: "Green",
        },
        {
          correct: true,
          content: "Red",
        },
        {
          correct: false,
          content: "Yellow",
        },
      ],
      feedback: {
        correct: 'You answered that question correctly!',
        incorrect: 'Sorry, you answered that question incorrectly.',
      }
    },
    htmlTemplate: '',
  },
  quizBlockEssay: {
    advancedTemplate: true,
    advancedTemplateReady: true,
    jsonTemplate: {
      type: ADVANCED_TYPES.ESSAY,
      question: 'What are your thoughts on stuff?',
      feedback: {
        neutral: 'Your response has been recorded. Think about your answer so you can remember it for later.',
      }
    },
    htmlTemplate: '',
  },
  quizBlockFillInTheBlank: {
    advancedTemplate: true,
    advancedTemplateReady: true,
    jsonTemplate: {
      type: ADVANCED_TYPES.FILL_IN_BLANK,
      data: {
        before: 'The stars at night',
        answer: 'are big and bright',
        after: 'deep in the heart of texas',
        placeholder: '',
        instructions: 'Please complete the following phrase',
        placement: "middle" /* start, end */
      },
      feedback: {
        correct: 'You answered that question correctly!',
        incorrect: 'Sorry, you answered that question incorrectly.',
      }
    },
    htmlTemplate: '',
  },
  mediaBlockVideo: {
    advancedTemplate: true,
    advancedTemplateReady: true,
    jsonTemplate: {
      type: ADVANCED_TYPES.VIDEO,
      embedCode: '<iframe src="https://player.vimeo.com/video/1084537" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
    },
    htmlTemplate: '',
  },
  mediaBlockFullRowVideo: {
    advancedTemplate: true,
    advancedTemplateReady: true,
    jsonTemplate: {
      type: ADVANCED_TYPES.FULL_ROW_VIDEO,
      embedCode: '<iframe src="https://player.vimeo.com/video/1084537" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
    },
    htmlTemplate: '',
    fullWidth: true
  }
};

export const templateConfigDefaults = {
  textBlockCourseTitle: {
    bg: "bg-primary",
  },
  cards: {
    padding: 5,
  },
  textAndIcon: {
    padding: 5,
  },
  imageBlockImageBannerLeft: {
    padding: 0,
  },
  imageBlockImageBannerRight: {
    padding: 0,
  },
  imageBlockImageCarousel: {
    padding: 0,
  },
  mediaBlockVideo: {
    padding: 0,
    bg: 'bg-black'
  },
  mediaBlockFullRowVideo: {
    padding: 0,
    bg: 'bg-black'
  },
};
