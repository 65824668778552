import React from "react";
import Link from "../navigation/Link";

const NotFound = (props) => (
  <div
    className={`text-center container o-visible ${window.ld_author_main} d-flex align-items-center justify-content-center flex-column`}
    style={{ height: "100vh" }}
  >
    <div>
      <h1>404 Page Not found</h1>
    </div>
    <div>
      <Link to="/">Return Home</Link>
    </div>
  </div>
);

export default NotFound;
