import fb from "./Firebase";

export default class ApiConnector {
  static async logOut() {
    return fb.auth().signOut();
  }
  /**
   *
   * @param userID
   * @param projectJSON
   * @returns {Promise<string>}
   */
  static async createProject(userID, projectJSON) {
    try {
      const ref = await fb.database().ref(`projects/${userID}`);
      const projectRef = await ref.push(projectJSON);
      return `/project/edit/${projectRef.key}`;
    } catch (e) {
      throw new Error("could not create project");
    }
  }

  static createUser({ email, password }, successCallback, errorCallback) {
    fb.auth()
      .createUserWithEmailAndPassword(email, password)
      .then(successCallback)
      .catch((e) => {
        errorCallback(e);
      });
  }

  /**
   *
   * @param {string} displayName
   * @param {function} updateAppState
   */
  static async registerUser(displayName, updateAppState) {
    await fb.auth().onAuthStateChanged((user) => {
      user
        .updateProfile({
          displayName,
        })
        .then(() => {
          updateAppState({
            user,
            displayName,
            userID: user.uid,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    });
  }

  /**
   *
   * @param {function} updateAppState
   */
  static verifyUser(updateAppState) {
    fb.auth().onAuthStateChanged((user) => {
      if (user) {
        updateAppState({
          user,
          displayName: user.displayName,
          userID: user.uid,
          appLoaded: true,
        });

        const projectsRef = fb.database().ref(`projects/${user.uid}`);

        projectsRef.on("value", (snapshot) => {
          const projectsSnapshot = snapshot.val();
          const projects = [];
          const projectsArchived = [];

          for (let projectID in projectsSnapshot) {
            const projectObj = {
              projectID,
              ...projectsSnapshot[projectID],
            };
            projectObj.archived
              ? projectsArchived.push(projectObj)
              : projects.push(projectObj);
          }

          updateAppState({
            projects,
            projectsArchived,
            projectsCount: projects.length,
            projectsArchivedCount: projectsArchived.length,
            projectsLoaded: true,
          });
        });
      } else {
        updateAppState({
          user: null,
          appLoaded: true,
        });
      }
    });
  }

  /**
   * @desc user login
   * @param {string} email
   * @param {string} password
   */
  static signIn(email, password) {
    return new Promise((resolve, reject) => {
      fb.auth()
        .signInWithEmailAndPassword(email, password)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  /**
   * @desc sends user a password reset email link
   * @param {string} email
   */
  static resetPassword(email) {
    return new Promise((resolve, reject) => {
      fb.auth()
        .sendPasswordResetEmail(email)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  /**
   * @desc updates a project with payload containing subset to be updated
   * @param {string} userID
   * @param {string} projectID
   * @param {object} payload
   */
  static updateProject(userID, projectID, payload) {
    return new Promise((resolve, reject) => {
      fb.database()
        .ref(`projects/${userID}/${projectID}`)
        .update(payload) // subset of the project object
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  /**
   *
   * @param {string} userID
   * @param {string} projectID
   * @param {func} projectLoadedCallback
   */
  static loadProject(userID, projectID, projectLoadedCallback) {
    try {
      fb.database()
        .ref(`projects/${userID}/${projectID}`)
        .on("value", projectLoadedCallback);
    } catch (err) {
      throw err;
    }
  }
}
