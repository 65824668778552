import React, { useState } from "react";
import Toast from "react-bootstrap/Toast";

function ToastMinimal({
  initShow,
  msg,
  onClose,
  variant = "danger",
  position = "top",
  offsetAmount = 0,
}) {
  const [show, setShow] = useState(initShow);

  return (
    <Toast
      onClose={() => {
        onClose && onClose();
        setShow(false);
      }}
      className={`minimal-toast toast-${variant} toast-position-${position}`}
      show={show}
      delay={5000}
      data-offsetAmount={offsetAmount}
    >
      <Toast.Body>
        <span>{msg}</span>
        <button className="btn btn-quiet float-right pull-right p-0 pl-3" style={{ fontSize: '24px', lineHeight: '1.4rem' }} onClick={() => {
          onClose && onClose();
          setShow(false);
        }}>&times;</button>
      </Toast.Body>
    </Toast>
  );
}

export default ToastMinimal;
