import React from "react";
import { ADVANCED_TYPES } from "../../utils/advancedTemplateDefinitions";

export default class AdvancedContent extends React.Component {
  render() {
    // const { ACCORDION, MULTIPLE_CHOICE, MULTIPLE_SELECT, ESSAY, FILL_IN_BLANK } = ADVANCED_TYPES;
    const type = this.props?.json?.type;
    const { id } = this.props;
    const { question, choices = [], feedback, data = {} } = this.props?.json;
    const { instructions,
      placement,
      placeholder,
      before,
      answer,
      after } = data;

    let renderContent, questionId;

    if (this.props?.json) {
      switch (type) {
        case ADVANCED_TYPES.ACCORDION:
          renderContent = (
            <div className="w-100">
              <div
                className="accordion custom-accordion"
                id={`accordion-${id}`}
              >
                {this.props?.json?.sections?.map(
                  ({ title, content }, index) => {

                    const uniqueIdentifier = `${id}-${index}`;
                    return (
                      <div className="card" key={`data-${uniqueIdentifier}`}>
                        <div className="card-header" id={`${uniqueIdentifier}`}>
                          <h4 className="mb-0 panel-title">
                            <a
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target={`#collapse-${uniqueIdentifier}`}
                              aria-expanded="false"
                              aria-controls={`collapse-${uniqueIdentifier}`}
                              href={`#collapse-${uniqueIdentifier}`}
                            >
                              {title}
                            </a>
                          </h4>
                        </div>
                        <div
                          id={`collapse-${uniqueIdentifier}`}
                          className="collapse"
                          aria-labelledby={`#${uniqueIdentifier}`}
                          data-parent={`#accordion-${id}`}
                        >
                          <div
                            className="card-body"
                            dangerouslySetInnerHTML={{
                              __html: content,
                            }}
                          />
                        </div>
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          );
          break;
        case ADVANCED_TYPES.MULTIPLE_CHOICE:
          questionId = `mc-question-${id}`;
          renderContent = (
            <div className="w-100 question-multiple-choice p-3">
              <p className="questionText">{question}</p>
              <div id={questionId}>
                {choices.map((choice, index) => {
                  const choiceId = `${questionId}-option-${index}`;
                  return (
                    <label key={choiceId} className="ld-multiple-choice">{choice.content}
                      <input className={`question-multiple-choice--option ${choice.correct ? 'correct' : ''}`} name="multiple-choice-example" type="radio" />
                      <span className="checkmark"></span>
                    </label>
                  )
                })}
              </div>
              <div className="d-flex justify-content-end w-100">
                <button type="button" className="btn btn-primary align-self-end mt-2 question-multiple-choice--submit" data-target={questionId}>Submit</button>
              </div>
              <div className="modal fade bd-example-modal-lg feedback-correct" id={`correctFeedback${questionId}`} tabIndex="-1" role="dialog"
                aria-labelledby="correctFeedbackLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="correctFeedbackLabel">Correct!</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>{feedback.correct}</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade bd-example-modal-lg feedback-incorrect" id={`incorrectFeedback${questionId}`} tabIndex="-1" role="dialog"
                aria-labelledby="incorrectFeedbackLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="incorrectFeedbackLabel">Incorrect</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>{feedback.incorrect}</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case ADVANCED_TYPES.MULTIPLE_SELECT:

          questionId = `ms-question-${id}`;
          renderContent = (
            <div className="w-100 question-multiple-select p-3">
              <p className="questionText">{question}</p>
              <div id={questionId}>
                {choices.map((choice, index) => {
                  const choiceId = `${questionId}-option-${index}`;
                  return (
                    <label key={choiceId} className="ld-multiple-select">{choice.content}
                      <input className={`question-multiple-select--option ${choice.correct ? 'correct' : ''}`} name="multiple-select-example" type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                    )
                  })}
                </div>
                <div className="d-flex justify-content-end w-100">
                <button type="button" className="btn btn-primary align-self-end mt-2 question-multiple-select--submit" data-target={questionId}>Submit</button>
              </div>
              <div className="modal fade bd-example-modal-lg feedback-correct" id={`correctFeedback${questionId}`} tabIndex="-1" role="dialog"
                aria-labelledby="correctFeedbackLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="correctFeedbackLabel">Correct!</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>{feedback.correct}</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade bd-example-modal-lg feedback-incorrect" id={`incorrectFeedback${questionId}`} tabIndex="-1" role="dialog"
                aria-labelledby="incorrectFeedbackLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="incorrectFeedbackLabel">Incorrect</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>{feedback.incorrect}</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case ADVANCED_TYPES.ESSAY:
          renderContent = (
            <div className="question-essay w-100 p-3">
              <p className="questionText">{question}</p>

              <div className="form-group">
                <textarea className="form-control question-essay--text" rows="3"></textarea>
              </div>
              <div className="d-flex justify-content-end w-100">
                <button type="button" className="btn btn-primary align-self-end mt-2 question-essay--submit">Submit</button>
              </div>

              <div className="modal fade bd-example-modal-lg feedback-correct" tabIndex="-1" role="dialog" aria-label="Correct"
                aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Thank you!</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>{feedback.neutral}</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          break;
        case ADVANCED_TYPES.FILL_IN_BLANK:
          renderContent = (
            <div className="question-fill-in-blank w-100 p-3">
              <p className="questionText">{instructions}</p>
              <div className="pb-4">
                {placement !== "start" && <span>{before}</span>}{" "}
                <span className="mx-1 border-dark border border-top-0 border-left-0 border-right-0 d-inline-block">
                  <input
                    placeholder={placeholder}
                    data-check={btoa(answer)}
                    className="question-fill-in-blank--input rounded-0 bg-lightest form-control d-inline p-0 text-center border-dark border border-top-0 border-left-0 border-right-0"
                    style={{ width: 10 + 10 * answer.length }}
                  />
                </span>{" "}
                {placement !== "end" && <span>{after}</span>}
              </div>

              <div className="d-flex">
                <button type="button" className="btn btn-primary align-self-end mt-2 question-fill-in-blank--submit">Submit</button>
              </div>

              <div className="modal fade bd-example-modal-lg feedback-correct" id={`correctFeedback${questionId}`} tabIndex="-1" role="dialog"
                  aria-labelledby="correctFeedbackLabel" aria-hidden="true">
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="correctFeedbackLabel">Correct!</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>{feedback.correct}</p>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
  
              <div className="modal fade bd-example-modal-lg feedback-incorrect" id={`incorrectFeedback${questionId}`} tabIndex="-1" role="dialog"
                  aria-labelledby="incorrectFeedbackLabel" aria-hidden="true">
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="incorrectFeedbackLabel">Incorrect</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>{feedback.incorrect}</p>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          );
          break;
        case ADVANCED_TYPES.VIDEO:
          renderContent = (
            <div className="container">
              <div className="embed-wrapper" dangerouslySetInnerHTML={{__html: this.props?.json.embedCode }} />
            </div>
          );
          break;
        case ADVANCED_TYPES.FULL_ROW_VIDEO:
          renderContent = (
            <div className="container-fluid">
              <div className="embed-wrapper" dangerouslySetInnerHTML={{__html: this.props?.json.embedCode }} />
            </div>
          );
          break;
        default:
          break;
      }
    }

    return (
      renderContent || (
        <div className="alert alert-danger">Error rendering content: {type}</div>
      )
    );
  }
}
