import React from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import fb from "../../api/Firebase";

import { Tabs, Tab, Overlay, Tooltip, Modal, Button } from "react-bootstrap";

import Project from "./ProjectTile";
import Footer from "../navigation/Footer";
import ProfileNavLink from "../users/ProfileNavLink";
import ModalProjectInfo from "./ModalProjectInfo";
import ProjectsEmpty from "./ProjectEmpty";

import Logo from "../graphics/Logo";
import { Plus } from "../graphics/Icons";
import { BASE_PATH } from "../../App";

import { navigate } from "@reach/router";

class Projects extends React.Component {
  state = {
    displayMode:
      /* window.localStorage.getItem("ldi_display_mode") || */ "grid",
    tabKey: "current",
    projectModalShow: false,
    currentProjectName: "",
    currentProjectDescription: "",
    currentProjectCoverPhoto: "",
    currentProject: {},
    editingProject: false,
  };

  componentDidMount() {
    this.setState({
      onBoardingTarget: React.createRef(),
    });
  }

  showProjectModal = () => {
    this.setState({
      projectModalShow: true,
    });
  };

  hideProjectModal = () => {
    this.setState({
      projectModalShow: false,
    });
  };

  updateDisplayMode = (newDisplayMode) => {
    window.localStorage.setItem("ldi_display_mode", newDisplayMode);
    this.setState({
      displayMode: newDisplayMode,
    });
  };

  setCurrentTab(tabKey) {
    this.setState({
      tabKey,
    });
  }

  handleDelete = (project) => {
    this.setState({
      modalContent: (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Permanent Action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to permanently delete this project?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleModalClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                this.handleModalClose();
                this.handleDeleteConfirmed(project);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </>
      ),
      modalShow: true,
    });
  };
  handleDeleteConfirmed = (project) => {
    const { projectID } = project;
    const { userID } = this.props;

    fb.database().ref(`projects/${userID}/${projectID}`).remove();
    navigate(BASE_PATH + "/");
  };
  handleModalClose = () => {
    this.setState({
      modalShow: false,
    });
  };
  handleEditInfo = () => {};

  handlePublish = (project) => {
    // const { userID } = this.props;
    // const { projectID } = project;
    // fb.database()
    //   .ref(`projects/${userID}/${projectID}`)
    //   .update({ ...project, published: true })
    //   .then(() => {
    //     navigate(BASE_PATH + `/project/${userID}/${projectID}`);
    //   });
    var zip = new JSZip();
    zip.file("Hello.txt", "Hello World\n");
    var img = zip.folder("images");
    img.file("smile.gif", "", { base64: true });
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${project.name}.zip`);
    });
  };
  handlePreview = (project) => {
    const { userID } = this.props;
    const { projectID } = project;
    fb.database()
      .ref(`projects/${userID}/${projectID}`)
      .update({ ...project })
      .then(() => {
        navigate(BASE_PATH + `/project/${userID}/${projectID}`);
      });
  };
  handleArchive = (project) => {
    const { userID } = this.props;
    const { projectID } = project;
    fb.database()
      .ref(`projects/${userID}/${projectID}`)
      .update({ ...project, archived: true })
      .then(() => {
        // TODO Toast?
        // navigate(BASE_PATH + `/project/${userID}/${projectID}`);
      });
  };
  handleRestore = (project) => {
    const { userID } = this.props;
    const { projectID } = project;
    fb.database()
      .ref(`projects/${userID}/${projectID}`)
      .update({ ...project, archived: false })
      .then(() => {
        // TODO Toast?
        // navigate(BASE_PATH + `/project/${userID}/${projectID}`);
      });
  };

  handleEditInfo = (project) => {
    const { name, description, thumbnail } = project;
    console.log(project);
    console.log({ name, description, thumbnail });
    this.setState(
      {
        currentProjectName: name,
        currentProjectDescription: description,
        currentProjectCoverPhoto: thumbnail,
        currentProject: project,
        editingProject: true,
      },
      () => {
        console.log(this.state.currentProjectCoverPhoto);
        setTimeout(() => {
          this.setState({
            projectModalShow: true,
          });
        }, 10);
      }
    );
  };

  render() {
    const { displayMode, tabKey, modalShow, modalContent } = this.state;
    const { projects, projectsArchived, user, userID } = this.props;
    return (
      <div className="container containerWider p-rel">
        <div
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <ProfileNavLink user={user} />
        </div>
        <div className="text-center">
          <Logo />
        </div>
        <div>
          <Tabs
            defaultActiveKey={tabKey}
            activeKey={tabKey}
            onSelect={(k) => this.setCurrentTab(k)}
          >
            <Tab eventKey="current" title="Current Projects">
              <section className={`projects ${displayMode}Display`}>
                {projects
                  .sort((a, b) => {
                    return ("" + b.projectID).localeCompare(a.projectID);
                  })
                  .map((project) => (
                    <Project
                      project={project}
                      key={project.projectID}
                      onPublish={this.handlePublish}
                      onPreview={this.handlePreview}
                      onArchive={this.handleArchive}
                      onDelete={this.handleDelete}
                      onEditInfo={this.handleEditInfo}
                    />
                  ))}
                <div
                  ref={this.state.onBoardingTarget}
                  id="onboarding-item"
                  onClick={() => {
                    this.setState({ onBoardingTarget: null });
                  }}
                >
                  <div
                    key={"project-initializer"}
                    className="projectWrapper new-project"
                    onClick={() => {
                      this.setState({
                        currentProjectName: "",
                        currentProjectDescription: "",
                        currentProjectCoverPhoto: "",
                        currentProject: {},
                        editingProject: false,
                      });
                      this.showProjectModal();
                    }}
                  >
                    <div className="project card">
                      <Plus />
                      <span className="text-gray py-2">
                        <strong>NEW PROJECT</strong>
                      </span>
                    </div>
                  </div>
                  <ModalProjectInfo
                    user={user}
                    userID={userID}
                    project={this.state.currentProject}
                    addProject={this.props.addProject}
                    handleClose={this.hideProjectModal}
                    show={this.state.projectModalShow}
                    projectName={this.state.currentProjectName}
                    projectDesc={this.state.currentProjectDescription}
                    projectCoverPhoto={this.state.currentProjectCoverPhoto}
                    editingProject={this.state.editingProject}
                  />
                </div>
                {this.state.onBoardingTarget &&
                  tabKey === "current" &&
                  projects.length === 0 && (
                    <Overlay
                      target={document.getElementById("onboarding-item")}
                      show={true}
                      placement="bottom"
                    >
                      {(props) => (
                        <Tooltip
                          {...props}
                          variant="info"
                          style={{
                            ...props.style,
                            marginTop: "-15px",
                          }}
                        >
                          Get started by creating your first project
                        </Tooltip>
                      )}
                    </Overlay>
                  )}
              </section>
              {/* projects.length === 0 && <ProjectsEmpty /> */}
            </Tab>
            <Tab eventKey="previous" title="Previous Projects">
              {projectsArchived.length === 0 ? (
                <ProjectsEmpty />
              ) : (
                <section className={`projects ${displayMode}Display`}>
                  {projectsArchived
                    .sort((a, b) => {
                      return ("" + b.projectID).localeCompare(a.projectID);
                    })
                    .map((project) => (
                      <Project
                        project={project}
                        key={project.projectID}
                        onPublish={this.handlePublish}
                        onRestore={this.handleRestore}
                        onPreview={this.handlePreview}
                        onArchive={this.handleArchive}
                        onDelete={this.handleDelete}
                        onEditInfo={this.handleEditInfo}
                        archived={true}
                      />
                    ))}
                </section>
              )}
            </Tab>
          </Tabs>
        </div>
        <Modal show={modalShow} onHide={this.handleModalClose}>
          {modalContent}
        </Modal>
        <Footer />
      </div>
    );
  }
}

export default Projects;
