import React from "react";
import Form from "react-bootstrap/Form";
import { LinkClosed, LinkOpen } from "../graphics/Icons";
import {
  colorOptions,
  spacingOptions,
  paddingDefault,
  marginDefault,
} from "../../utils/defaultProperties";

const PropertiesPanel = (props) => {
  const { config, id, updateSectionConfig } = props;
  const { paddingLinked = true, marginLinked = true } = config || {};

  const updatePaddingLinkage = (e) => {
    const val = e.target.checked;
    updateSectionConfig(id, {
      paddingLinked: val,
      padding: !val
        ? {
            x: paddingDefault.x,
            y: paddingDefault.y,
          }
        : paddingDefault.y,
    });
  };

  const updateMarginLinkage = (e) => {
    const val = e.target.checked;
    updateSectionConfig(id, {
      marginLinked: val,
      margin: !val
        ? {
            x: marginDefault.x,
            y: marginDefault.y,
          }
        : marginDefault.y,
    });
  };

  const paddingArr = paddingLinked ? [""] : ["x", "y"];
  const marginArr = marginLinked ? [""] : ["x", "y"];

  return (
    <div className="p-3 add-section-popover section-properties dojo-editor">
      <strong className="text-gray-mid">SECTION PROPERTIES</strong>
      <hr className="mt-2 mb-3 p-0" />
      <div className="label linkageToggle">
        <span>Padding</span>
        <Form.Check
          label={paddingLinked ? <LinkClosed /> : <LinkOpen />}
          checked={paddingLinked}
          onChange={updatePaddingLinkage}
        />
      </div>
      <div className="row">
        {paddingArr.map((paddingDimension) => (
          <div
            key={`property-padding-${paddingDimension}`}
            className={`col ${paddingDimension !== "" ? "rangesUnlinked" : ""}`}
          >
            <div className="rangeContainer mb-5">
              {paddingArr.length > 1 && (
                <small className="label label-small">
                  Padding {paddingDimension}
                </small>
              )}
              <input
                className="rangeSlider"
                type="range"
                min="0"
                max="5"
                step="1"
                value={
                  paddingDimension !== "" && typeof config?.padding === "object"
                    ? config?.padding[paddingDimension] ||
                      paddingDefault[paddingDimension]
                    : typeof config?.padding !== "undefined"
                    ? config?.padding
                    : paddingDefault.y
                }
                onChange={(e) => {
                  const padding =
                    paddingDimension === ""
                      ? e.target.value
                      : {
                          ...config.padding,
                          [paddingDimension]: e.target.value,
                        };
                  updateSectionConfig(id, {
                    padding,
                  });
                }}
              />
              <ul className="rangeLabelList">
                {spacingOptions.map((spacing, index) => (
                  <li
                    key={`padding-select-${id}-${spacing}`}
                    className={`rangeLabel ${
                      config?.padding === `p-${index}` ? "active" : ""
                    }`}
                  >
                    {spacing}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      <div className="label linkageToggle">
        <span>Margin</span>
        <Form.Check
          label={marginLinked ? <LinkClosed /> : <LinkOpen />}
          checked={marginLinked}
          onChange={updateMarginLinkage}
        />
      </div>
      <div className="row">
        {marginArr.map((marginDimension) => (
          <div
            key={`property-margin-${marginDimension}`}
            className={`col ${marginDimension !== "" ? "rangesUnlinked" : ""}`}
          >
            <div className="rangeContainer mb-5">
              {marginArr.length > 1 && (
                <small className="label label-small">
                  Margin {marginDimension}
                </small>
              )}
              <input
                className="rangeSlider"
                type="range"
                min="0"
                max="5"
                step="1"
                value={
                  marginDimension !== "" && typeof config?.margin === "object"
                    ? config?.margin[marginDimension] ||
                      marginDefault[marginDimension]
                    : typeof config?.margin !== "undefined"
                    ? config?.margin
                    : marginDefault.y
                }
                onChange={(e) => {
                  const margin =
                    marginDimension === ""
                      ? e.target.value
                      : {
                          ...config.margin,
                          [marginDimension]: e.target.value,
                        };
                  updateSectionConfig(id, {
                    margin,
                  });
                }}
              />
              <ul className="rangeLabelList">
                {spacingOptions.map((spacing, index) => (
                  <li
                    key={`margin-select-${id}-${spacing}`}
                    className={`rangeLabel ${
                      config?.margin === `p-${index}` ? "active" : ""
                    }`}
                  >
                    {spacing}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      <span className="label">Background</span>
      <ul className="color-selector mb-4">
        {colorOptions.map((color) => (
          <li
            tabIndex={0}
            key={`bg-${color}`}
            onClick={updateSectionConfig.bind(null, id, {
              bg: `bg-${color}`,
            })}
            onKeyPress={updateSectionConfig.bind(null, id, {
              bg: `bg-${color}`,
            })}
            className={`bg-${color} ${
              config?.bg === `bg-${color}` ? "active" : ""
            }`}
          ></li>
        ))}
      </ul>
      <span className="label">Text Color</span>
      <ul className="color-selector">
        {colorOptions.map((color) => (
          <li
            tabIndex={0}
            key={`text-${color}`}
            onClick={updateSectionConfig.bind(null, id, {
              color: `text-${color}`,
            })}
            onKeyPress={updateSectionConfig.bind(null, id, {
              color: `text-${color}`,
            })}
            className={`bg-${color} ${
              config?.color === `text-${color}` ? "active" : ""
            }`}
          ></li>
        ))}
      </ul>
    </div>
  );
};

export default PropertiesPanel;
