import React from "react";

const Footer = (props) => {
  return (
    <div className={window.ld_author_main}>
      <div className="Footer">
        &copy; {new Date().getFullYear()} Learning Dojo
      </div>
    </div>
  );
};

export default Footer;
