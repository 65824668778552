import React from "react";
import { ADVANCED_TYPES } from "../../utils/advancedTemplateDefinitions";
import { Trash, Plus } from "../../components/graphics/Icons";
import FillInTheBlank from './types/FillInTheBlank';
import sanitizeHtml from "sanitize-html";


/**
 * Adjust this according to what needs to be supported in the Wysiwyg portion of the editor
 */
const sanitizeHtmlOptions = {
  // ...sanitizeHtml.options,
  // Warning - better to have allowedList
  // allowedTags: false,
  // allowedAttributes: false,
  allowedAttributes: {
    "*": [
      "src",
      "allow",
      "allowfullscreen",
      "frameborder"
    ],
  },
  allowedTags: [
    "iframe",
  ],
};

const sanitizeString = (html) => sanitizeHtml(html, sanitizeHtmlOptions);

export default class AdvancedEditor extends React.Component {
  state = {
    activeIndex: 0,
    // sections: this.props?.json?.sections || [],
    json: this.props?.json,
    richEditorSelected: false,
  };

  componentDidMount() {
    this.richContentRef = React.createRef();
  }

  formatElement = (e) => {
    const { cmd } = e.target.dataset;
    let val = "";
    document.execCommand(cmd, false, val);
  };

  addSectionBlock = () => {
    this.setState({
      json: {
        ...this.state.json,
        sections: [
          ...this.state.json.sections,
          {
            title: "",
            content: "",
          },
        ],
      },
      activeIndex: this.state.json.sections.length,
    });
  };

  render() {
    const { ACCORDION, MULTIPLE_CHOICE, MULTIPLE_SELECT, ESSAY, FILL_IN_BLANK } = ADVANCED_TYPES;
    const { json, onSave } = this.props;
    const type = json?.type;
    const { activeIndex } = this.state;
    const { sections, choices = [] } = this.state.json;

    let renderContent;

    if (json) {
      switch (type) {
        case ACCORDION:
          renderContent = (
            <div
              className="dojo-editor display-flex"
              style={{ minHeight: "70vh" }}
            >
              <div className="bg-gray-light" style={{ width: "250px" }}>
                <div className="p-3">
                  <strong>ACCORDION BUILDER</strong>
                  <br />
                  <p>
                    Add the desired accordion sections below and then fill out
                    the content on the right.
                  </p>
                </div>
                <strong className="p-2">Blocks</strong>
                <div className="pb-5">
                  {sections.map((section, index) => {
                    return (
                      <button
                        className={`btn tab-btn ${index === this.state.activeIndex
                          ? "tab-btn-active"
                          : ""
                          }`}
                        key={`block-btn-${index}`}
                        onClick={() => {
                          this.setState({
                            activeIndex: index,
                            richEditorSelected: false,
                          });
                        }}
                      >
                        <span>Block {index + 1}</span>{" "}
                        <span
                          className="tab-remove"
                          onClick={(e) => {
                            e.stopPropagation();
                            // 1. Make a shallow copy of the items
                            let items = [...this.state.json.sections];

                            items.splice(index, 1);
                            this.setState({
                              json: {
                                ...this.state.json,
                                sections: items,
                              },
                              activeIndex: Math.min(
                                items.length - 1,
                                this.state.activeIndex
                              ),
                            });
                          }}
                        >
                          <Trash />
                        </span>
                      </button>
                    );
                  })}
                  <button
                    className="btn-quiet w-100 text-center"
                    onClick={this.addSectionBlock}
                  >
                    + New Block
                  </button>
                </div>
              </div>
              <div className="shadow bg-white p-2" style={{ flex: 1 }}>
                {sections.map((section, index) => {
                  const id = `section-content-${index}-${this.props.id}`;
                  return (
                    index === activeIndex && (
                      <div
                        key={id}
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className="form-group p-3">
                          <label htmlFor={`${id}-title`}>
                            <strong>Block Title</strong>
                          </label>
                          <input
                            className="form-control"
                            id={`${id}-title`}
                            value={section.title}
                            onChange={(e) => {
                              // 1. Make a shallow copy of the items
                              let items = [...this.state.json.sections];
                              // 2. Make a shallow copy of the item you want to mutate
                              let item = { ...sections[index] };
                              // 3. Replace the property you're intested in
                              item.title = e.target.value;
                              // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                              items[index] = item;
                              // 5. Set the state to our new copy
                              this.setState({
                                json: {
                                  ...this.state.json,
                                  sections: items,
                                },
                              });
                            }}
                          />
                        </div>
                        <div
                          className="form-group px-3 pb-3"
                          style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <label htmlFor={`${id}-content`}>
                            <strong>Block Content</strong>
                          </label>
                          <div
                            className="rich-editor"
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              className={`form-control auto-height rich-editor-content ${this.state.richEditorSelected && "selected"
                                }`}
                              id={`${id}-title`}
                              contentEditable
                              dangerouslySetInnerHTML={{
                                __html: section.content,
                              }}
                              style={{ flex: 1 }}
                              onFocus={() => {
                                this.setState({
                                  richEditorSelected: true,
                                });
                              }}
                              onBlur={(e) => {
                                // 1. Make a shallow copy of the items
                                let items = [...this.state.json.sections];
                                // 2. Make a shallow copy of the item you want to mutate
                                let item = { ...sections[index] };
                                // 3. Replace the property you're intested in
                                item.content = e.target.innerHTML;
                                if (typeof item.content === "undefined")
                                  item.content = "";
                                // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                                items[index] = item;
                                // 5. Set the state to our new copy
                                this.setState({
                                  json: {
                                    ...this.state.json,
                                    sections: items,
                                  },
                                });
                              }}
                              ref={this.richContentRef}
                            />
                            <div className="editor-controls">
                              <input
                                type="button"
                                value="B"
                                data-cmd="bold"
                                onClick={this.formatElement}
                                style={{ fontWeight: "bold" }}
                              />
                              <input
                                type="button"
                                value="It"
                                data-cmd="italic"
                                onClick={this.formatElement}
                                style={{ fontStyle: "italic" }}
                              />
                              <input
                                type="button"
                                value="U"
                                data-cmd="underline"
                                onClick={this.formatElement}
                                style={{ textDecoration: "underline" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group pb-2 text-center">
                          <button
                            className="btn btn-primary"
                            style={{
                              minWidth: "150px",
                              textTransform: "uppercase",
                            }}
                            onClick={() => {
                              onSave(this.state.json);
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          );
          break;
        case MULTIPLE_CHOICE:
          renderContent = (
            <div
              className="dojo-editor display-flex"
              style={{ minHeight: "70vh" }}
            >
              <div className="shadow bg-white p-5 mc-edit" style={{ flex: 1 }}>
                <h3>Multiple Choice Editor</h3>
                <hr className="my-3" style={{ opacity: '0.2' }} />
                <strong className="py-4">Question</strong>
                <textarea
                  className="form-control mb-4"
                  value={this.state?.json?.question}
                  onChange={e => {
                    const question = e.target.value;
                    this.setState(prevState => ({
                      json: {
                        ...prevState.json,
                        question
                      }
                    }))
                  }}
                />
                <strong className="mt-4 mb-2">Choices</strong>
                <div className="row my-1">
                  <div className="col-1">
                    <small>Correct</small>
                  </div>
                  <div className="col-11">
                    <small>Text</small>
                  </div>
                </div>
                {(choices).map(
                  ({ correct, content }, index) => {
                    const id = `multiple-choice-option-${index}-${this.props.id}`;
                    return (
                      <div className="form-group row" key={id}>
                        <div className="form-check py-2 col-1 text-center">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`multiple-choice-${this.props.id}`}
                            id={`${id}`}
                            value={`${id}`}
                            checked={correct}
                            tabIndex={0}
                            onChange={(e) => {
                              if (e.target.checked) {
                                const choices = [...this.state?.json?.choices].map((choice, i) => {
                                  return {
                                    ...choice,
                                    correct: index === i && e.target.checked,
                                  }
                                })
                                this.setState(prevState => ({
                                  json: {
                                    ...prevState.json,
                                    choices
                                  }
                                }))
                              }
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`${id}`}
                          ></label>
                        </div>
                        <div className="col-11">
                          <div className="w-100 d-flex align-items-center">
                            <input
                              className="form-control mr-2"
                              value={content}
                              placeholder="Enter choice text"
                              onChange={(e) => {
                                const choices = [...this.state?.json?.choices];
                                choices[index].content = e.target.value;
                                this.setState(prevState => ({
                                  json: {
                                    ...prevState.json,
                                    choices
                                  }
                                }))
                              }}
                            />
                            <span
                              className="tab-remove"
                              onClick={(e) => {
                                e.stopPropagation();
                                // 1. Make a shallow copy of the items
                                let items = [...this.state.json.choices];

                                items.splice(index, 1);
                                this.setState({
                                  json: {
                                    ...this.state.json,
                                    choices: items,
                                  },
                                  activeIndex: Math.min(
                                    items.length - 1,
                                    this.state.activeIndex
                                  ),
                                });
                              }}
                            >
                              <Trash />
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}


                <button className="btn btn-quiet icon-match row mb-2 d-flex w-100 text-left align-items-center" onClick={e => {
                  this.setState((prevState) => ({
                    json: {
                      ...prevState.json,
                      choices: [
                        ...(prevState.json.choices || []),
                        {
                          correct: false,
                          content: '',
                        }
                      ]
                    }
                  }))
                }}>
                  <div className="col-1 d-flex align-items-center"><Plus style={{ width: '26px' }} /></div>
                  <div className="col-9 d-flex align-items-center"><span>Add choice</span></div>
                </button>

                <strong className="py-4">Feedback</strong>
                <div className="row">
                  <div className="col">
                    <div className="text-success"><strong>Correct</strong></div>
                    <textarea
                      className="form-control mb-4"
                      value={this.state?.json?.feedback?.correct}
                      onChange={e => {
                        const correct = e.target.value;
                        this.setState(prevState => ({
                          json: {
                            ...prevState.json,
                            feedback: {
                              ...prevState.json.feedback,
                              correct
                            }
                          }
                        }))
                      }}
                    />
                  </div>
                  <div className="col">
                    <div className="text-danger"><strong>Incorrect</strong></div>
                    <textarea
                      className="form-control mb-4"
                      value={this.state?.json?.feedback?.incorrect}
                      onChange={e => {
                        const incorrect = e.target.value;
                        this.setState(prevState => ({
                          json: {
                            ...prevState.json,
                            feedback: {
                              ...prevState.json.feedback,
                              incorrect
                            }
                          }
                        }))
                      }}
                    />
                  </div>
                </div>

                <div className="form-group pb-2 text-right mt-5">
                  <hr className="mt-5" style={{ opacity: '0.2' }} />
                  <button
                    className="btn btn-primary"
                    style={{
                      minWidth: "150px",
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      onSave(this.state.json);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div >
          );
          break;
        case MULTIPLE_SELECT:
          renderContent = (
            <div
              className="dojo-editor display-flex"
              style={{ minHeight: "70vh" }}
            >
              <div className="shadow bg-white p-5 mc-edit" style={{ flex: 1 }}>
                <h3>Multiple Select Editor</h3>
                <hr className="my-3" style={{ opacity: '0.2' }} />
                <strong className="py-4">Question</strong>
                <textarea
                  className="form-control mb-4"
                  value={this.state?.json?.question}
                  onChange={e => {
                    const question = e.target.value;
                    this.setState(prevState => ({
                      json: {
                        ...prevState.json,
                        question
                      }
                    }))
                  }}
                />
                <strong className="mt-4 mb-2">Choices</strong>
                <div className="row my-1">
                  <div className="col-1">
                    <small>Correct</small>
                  </div>
                  <div className="col-11">
                    <small>Text</small>
                  </div>
                </div>
                {(choices).map(
                  ({ correct, content }, index) => {
                    const id = `multiple-select-option-${index}-${this.props.id}`;
                    return (
                      <div className="form-group row" key={id}>
                        <div className="form-check py-2 col-1 text-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={`multiple-select-${this.props.id}`}
                            id={`${id}`}
                            value={`${id}`}
                            checked={correct}
                            tabIndex={0}
                            onChange={(e) => {
                              const choices = [...this.state?.json?.choices].map((choice, i) => {
                                return {
                                  ...choice,
                                  correct: index === i ? e.target.checked : choice.correct,
                                }
                              })
                              this.setState(prevState => ({
                                json: {
                                  ...prevState.json,
                                  choices
                                }
                              }));
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`${id}`}
                          ></label>
                        </div>
                        <div className="col-11">
                          <div className="w-100 d-flex align-items-center">
                            <input
                              className="form-control mr-2"
                              value={content}
                              placeholder="Enter choice text"
                              onChange={(e) => {
                                const choices = [...this.state?.json?.choices];
                                choices[index].content = e.target.value;
                                this.setState(prevState => ({
                                  json: {
                                    ...prevState.json,
                                    choices
                                  }
                                }))
                              }}
                            />
                            <span
                              className="tab-remove"
                              onClick={(e) => {
                                e.stopPropagation();
                                // 1. Make a shallow copy of the items
                                let items = [...this.state.json.choices];

                                items.splice(index, 1);
                                this.setState({
                                  json: {
                                    ...this.state.json,
                                    choices: items,
                                  },
                                  activeIndex: Math.min(
                                    items.length - 1,
                                    this.state.activeIndex
                                  ),
                                });
                              }}
                            >
                              <Trash />
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}


                <button className="btn btn-quiet icon-match row mb-2 d-flex w-100 text-left align-items-center" onClick={e => {
                  this.setState((prevState) => ({
                    json: {
                      ...prevState.json,
                      choices: [
                        ...(prevState.json.choices || []),
                        {
                          correct: false,
                          content: '',
                        }
                      ]
                    }
                  }))
                }}>
                  <div className="col-1 d-flex align-items-center"><Plus style={{ width: '26px' }} /></div>
                  <div className="col-9 d-flex align-items-center"><span>Add choice</span></div>
                </button>

                <strong className="py-4">Feedback</strong>
                <div className="row">
                  <div className="col">
                    <div className="text-success"><strong>Correct</strong></div>
                    <textarea
                      className="form-control mb-4"
                      value={this.state?.json?.feedback?.correct}
                      onChange={e => {
                        const correct = e.target.value;
                        this.setState(prevState => ({
                          json: {
                            ...prevState.json,
                            feedback: {
                              ...prevState.json.feedback,
                              correct
                            }
                          }
                        }))
                      }}
                    />
                  </div>
                  <div className="col">
                    <div className="text-danger"><strong>Incorrect</strong></div>
                    <textarea
                      className="form-control mb-4"
                      value={this.state?.json?.feedback?.incorrect}
                      onChange={e => {
                        const incorrect = e.target.value;
                        this.setState(prevState => ({
                          json: {
                            ...prevState.json,
                            feedback: {
                              ...prevState.json.feedback,
                              incorrect
                            }
                          }
                        }))
                      }}
                    />
                  </div>
                </div>

                <div className="form-group pb-2 text-right mt-5">
                  <hr className="mt-5" style={{ opacity: '0.2' }} />
                  <button
                    className="btn btn-primary"
                    style={{
                      minWidth: "150px",
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      onSave(this.state.json);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div >
          );
          break;

        case ESSAY:
          renderContent = (
            <div
              className="dojo-editor display-flex"
            >
              <div className="shadow bg-white p-5 mc-edit" style={{ flex: 1 }}>
                <h3>Essay Question Editor</h3>
                <hr className="my-3" style={{ opacity: '0.2' }} />
                <strong className="py-4">Question</strong>
                <textarea
                  className="form-control mb-4"
                  value={this.state?.json?.question}
                  onChange={e => {
                    const question = e.target.value;
                    this.setState(prevState => ({
                      json: {
                        ...prevState.json,
                        question
                      }
                    }))
                  }}
                />

                <strong className="py-4">Feedback</strong>
                <div className="row">
                  <div className="col">
                    <textarea
                      className="form-control mb-4"
                      value={this.state?.json?.feedback?.neutral}
                      onChange={e => {
                        const neutral = e.target.value;
                        this.setState(prevState => ({
                          json: {
                            ...prevState.json,
                            feedback: {
                              ...prevState.json.feedback,
                              neutral
                            }
                          }
                        }))
                      }}
                    />
                  </div>
                </div>

                <div className="form-group pb-2 text-right mt-5">
                  <hr className="mt-5" style={{ opacity: '0.2' }} />
                  <button
                    className="btn btn-primary"
                    style={{
                      minWidth: "150px",
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      onSave(this.state.json);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div >
          );
          break;
        case FILL_IN_BLANK:

          renderContent = (
            <div className="dojo-editor display-flex">
              <div className="shadow bg-white p-5 mc-edit" style={{ flex: 1 }}>
                <h3>Fill-in the Blank Editor</h3>
                <hr className="my-3" style={{ opacity: '0.2' }} />

                <FillInTheBlank data={this.state?.json?.data} updateData={({ key, val }) => {
                  console.log({ key, val });
                  this.setState(prevState => ({
                    json: {
                      ...prevState.json,
                      data: {
                        ...prevState.json.data,
                        [key]: val
                      }
                    }
                  }))
                }} />

                <strong className="py-4">Feedback</strong>
                <div className="row">
                  <div className="col">
                    <div className="text-success"><strong>Correct</strong></div>
                    <textarea
                      className="form-control mb-4"
                      value={this.state?.json?.feedback?.correct}
                      onChange={e => {
                        const correct = e.target.value;
                        this.setState(prevState => ({
                          json: {
                            ...prevState.json,
                            feedback: {
                              ...prevState.json.feedback,
                              correct
                            }
                          }
                        }))
                      }}
                    />
                  </div>
                  <div className="col">
                    <div className="text-danger"><strong>Incorrect</strong></div>
                    <textarea
                      className="form-control mb-4"
                      value={this.state?.json?.feedback?.incorrect}
                      onChange={e => {
                        const incorrect = e.target.value;
                        this.setState(prevState => ({
                          json: {
                            ...prevState.json,
                            feedback: {
                              ...prevState.json.feedback,
                              incorrect
                            }
                          }
                        }))
                      }}
                    />
                  </div>
                </div>

                <div className="form-group pb-2 text-right mt-5">
                  <hr className="mt-5" style={{ opacity: '0.2' }} />
                  <button
                    className="btn btn-primary"
                    style={{
                      minWidth: "150px",
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      onSave(this.state.json);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          );
          break;
        case ADVANCED_TYPES.VIDEO:
          renderContent = (
            <div className="dojo-editor display-flex">
              <div className="shadow bg-white p-5 mc-edit" style={{ flex: 1 }}>
                <h3>Video Editor</h3>
                <hr className="my-3" style={{ opacity: '0.2' }} />
                <textarea placeholder={`Embedded video code, such as: <iframe src="https://player.vimeo.com/video/1084537" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`} className="form-control mb-3" onChange={(e) => {
                  const embedCode = sanitizeString(e.target.value);
                  console.log(embedCode);
                  const json = {
                    ...this.state.json,
                    embedCode
                  };
                  this.setState({
                    json
                  })

                }} value={this.state.json.embedCode} />
                <div className="embed-wrapper bg-white" dangerouslySetInnerHTML={{ __html: this.state.json.embedCode }} />
                <div className="form-group pb-2 text-right mt-3">
                  <button
                    className="btn btn-primary"
                    style={{
                      minWidth: "150px",
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      onSave(this.state.json);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          );
          break;
        case ADVANCED_TYPES.FULL_ROW_VIDEO:
          renderContent = (
            <div className="dojo-editor display-flex">
              <div className="shadow bg-white p-5 mc-edit" style={{ flex: 1 }}>
                <h3>Full Row Video Editor</h3>
                <hr className="my-3" style={{ opacity: '0.2' }} />
                <textarea placeholder={`Embedded video code, such as: <iframe src="https://player.vimeo.com/video/1084537" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`} className="form-control mb-3" onChange={(e) => {
                  const embedCode = sanitizeString(e.target.value);
                  console.log(embedCode);
                  const json = {
                    ...this.state.json,
                    embedCode
                  };
                  this.setState({
                    json
                  })

                }} value={this.state.json.embedCode} />
                <div className="embed-wrapper bg-white" dangerouslySetInnerHTML={{ __html: this.state.json.embedCode }} />
                <div className="form-group pb-2 text-right mt-3">
                  <button
                    className="btn btn-primary"
                    style={{
                      minWidth: "150px",
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      onSave(this.state.json);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          );
          break;
        default:
          break;
      }
    }

    return (
      <>
        <button className="unstyled modal-close" onClick={this.props.onClose}>&times;</button>
        {renderContent || (
          <div className="text-danger p-5">Error rendering content: {type}</div>
        )}
      </>
    );
  }
}
