import React from "react";
import { Router, navigate } from "@reach/router";

import ApiConnector from "./api/ApiConnector";

import Login from "./components/users/Login";
import Profile from "./components/users/Profile";
import Loading from "./components/graphics/Loading";
import Register from "./components/users/Register";
import NotFound from "./components/navigation/NotFound";
import Projects from "./components/projects/ProjectList";
import ProjectEditor from "./components/projects/ProjectEditor";
import ProjectPresenter from "./components/projects/ProjectPresenter";
import ProjectPreview from "./components/projects/ProjectPreview";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";

library.add(faChevronLeft, faChevronRight);

// export const BASE_PATH =
//   process.env.NODE_ENV === "development" ? "" : "/learning-dojo-author";
export const BASE_PATH = "";

const DEFAULT_DISPLAY_MODE = "grid";
const DEFAULT_THEME = "light";

class App extends React.Component {
  state = {
    user: null,
    displayName: null,
    userID: null,
    projects: [],
    appLoaded: false,
    projectsLoaded: false,
    displayMode: DEFAULT_DISPLAY_MODE,
    theme: DEFAULT_THEME
  };

  updateAppState = data => this.setState({ ...data });

  componentDidMount() {
    ApiConnector.verifyUser(this.updateAppState);
  }

  registerUser = async displayName => {
    await ApiConnector.registerUser(displayName, this.updateAppState);
    navigate(BASE_PATH + "/");
  };

  clearUser = () => {
    this.setState({
      displayName: null,
      userID: null,
      user: null
    });
  };

  addProject = async projectJSON => {
    const projectPath = await ApiConnector.createProject(
      this.state.userID,
      projectJSON
    );
    navigate(`${BASE_PATH}${projectPath}`);
  };

  render() {
    const {
      user,
      userID,
      projects,
      projectsArchived,
      appLoaded,
      projectsLoaded
    } = this.state;
    return (
      <>
        {appLoaded ? (
          <Router basepath={BASE_PATH}>
            <Login path="/login" />
            <Login path="/login/:chosenEmail" />
            <Profile path="/profile" user={user} clearUser={this.clearUser} />
            <Register path="/register" registerUser={this.registerUser} />
            <Register
              path="/register/:chosenEmail"
              registerUser={this.registerUser}
            />
            {!user && <Login path="/" />}
            {user && projectsLoaded && (
              <Projects
                path="/"
                projects={projects}
                projectsArchived={projectsArchived}
                user={user}
                userID={userID}
                addProject={this.addProject}
              />
            )}
            {user && !projectsLoaded && <Loading path="/" />}
            {user && (
              <ProjectEditor
                path="/project/new"
                userID={userID}
                addProject={this.addProject}
                newProject={true}
              />
            )}
            {user && (
              <ProjectEditor path="/project/edit/:projectID" userID={userID} />
            )}
            <ProjectPreview
              path="/preview/:userID/:projectID"
              userID={userID}
            />
            <ProjectPresenter
              path="/project/:userID/:projectID"
              userID={userID}
            />
            <NotFound default />
          </Router>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

export default App;
