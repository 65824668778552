import React from 'react';

const ImageEditModal = ({ onHide, item }) => {
    const bgImage = !item.src;
    let [currentImage, setCurrentImage] = React.useState(bgImage ? window.getComputedStyle(item, null).getPropertyValue('background-image') : item.src);
    const [currentHeight, setCurrentHeight] = React.useState(item.getBoundingClientRect().height);

    return (
        <>
            <button className="unstyled modal-close" onClick={onHide}>&times;</button>
            <div className="px-3 py-5 dojo-editor">
                <h2>Update {bgImage ? 'Background' : 'Image'}</h2>
                <input className="form-control my-3" value={bgImage ? currentImage.split('url("')[1].split('")')[0] : currentImage} onChange={(e) => {
                    if(bgImage) {
                        item.style.backgroundImage = currentImage.split('url("')[0] + 'url("' + e.target.value + '")' + currentImage.split('")')[1];
                    } else {
                        item.src = e.target.value;
                    }
                    setCurrentImage(bgImage ? currentImage.split('url("')[0] + 'url("' + e.target.value + '")' + currentImage.split('")')[1] : e.target.value);
                    setTimeout(() => {
                        setCurrentHeight(item.getBoundingClientRect().height);
                    },200);
                }} />
                <div style={{transition: 'height 400ms ease', display:'block', height:currentHeight, maxHeight:'70vh', border:'1px solid #88aaff', backgroundSize:bgImage ? 'cover' : 'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center center', backgroundImage:bgImage ? currentImage : `url(${currentImage})`}} />
            </div>
        </>
    )
};

export default ImageEditModal;