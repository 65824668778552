import React from "react";
import { v4 as uuidv4 } from "uuid";
import Link from "../navigation/Link";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { navigate } from "@reach/router";
import { BASE_PATH } from "../../App";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Loading from "../graphics/Loading";
import ToastMinimal from "../errorHandling/ToastMinimal";
import ModalAddContentBlock from "./ModalAddContentBlock";
import Modal from "react-bootstrap/Modal";
import AddBlockBtn from "./AddBlockBtn";
import ImageEditModal from './ImageEditModal';
import {
  BackHome,
  Drag,
  Trash,
  Phone,
  Publish,
  PlusPage,
  Page,
  Code,
  PlusBlock,
  Pencil,
  Chevron,
  AddPage,
  AddFolder,
  Menu,
  Warning,
} from "../graphics/Icons";
import ApiConnector from "../../api/ApiConnector";
import Editor from "react-simple-code-editor";
import { Popover, Overlay } from "react-bootstrap";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import {
  setCursorPos,
  getCursorPos,
} from "../../utils/textFormatting";
import { getSectionClassName } from "../../utils/rendering";
import PropertiesPanel from "./PropertiesPanel";
import "../../ninja-main/main.css";
import AdvancedContent from "../advancedEditing/AdvancedContent";
import AdvancedEditor from "../advancedEditing/AdvancedEditor";

const codeViewEnabled = true;

/**
 * Adjust this according to what needs to be supported in the Wysiwyg portion of the editor
 */
const sanitizeHtmlOptions = {
  allowedTags: false,
  allowedAttributes: false
};

// TODO - fix / use actual sanitization

const cleanIndentation = (html) => html;
const sanitizeHtml = (html, options) => html;


/**
 * @class
 * @desc ProjectEditor
 */
class ProjectEditor extends React.Component {
  initialState = {
    name: "",
    scorm: "",
    description: "",
    thumbnail: "",
    progress: "",
    sections: [],
    menu: [],
    pages: [],
    activePageIndex: 0,
    activeSubPageIndex: 0,
    fileUpload: "",
    projectLoaded: false,
    errorMessage: null,
    isSaving: false,
    draggingOver: false,
    showBlockModal: false,
    codeView: false,
    activeColumnId: null,
    activeSectionId: null,
    activeColCount: 0,
    sectionAddPopoverShow: false,
    sectionPropertiesPopoverShow: false,
    toastShow: false,
    toastMsg: "",
    toastVariant: null,
    pageNavigationShowing: true,
    showModal: false,
    modalContent: <></>,
    advancedTemplateJSON: {},
    currentEditingImage: null,
    currentImage:'',
    containerHeight: 400,
    fullHeightSection: true
  };
  state = this.initialState;

  constructor() {
    super();
    this.scrollContainer = React.createRef();
    this.sectionAddButton = React.createRef();
    window.addEventListener('resize', this.updateScrollContainerHeight);
  }

  bindImages = () => {
    const images = [...document.querySelectorAll(".content-editor .content-editable-col img"), ...document.querySelectorAll(".content-editor .imageBanner")];
    for (const img of images) {
      img.addEventListener('click', this.showImageModal);
      img.addEventListener('click', this.showImageModal);
    }
  }

  showImageModal = (event) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    this.setState({
      showModal: true,
      modalContent:  <ImageEditModal item={event.target} onHide={() => {
        this.setState({
          showModal: false
        })
      }} />
    })
  }

  updateScrollContainerHeight = () => {
    this.setState({
      containerHeight: this.scrollContainer.current?.getBoundingClientRect().height - 10,
    })
  }

  componentDidMount() {
    const { userID, projectID, newProject } = this.props;

    this.updateScrollContainerHeight();

    if (newProject) {
      this.setState({
        projectLoaded: true,
      });
    } else {
      ApiConnector.loadProject(userID, projectID, (snapshot) => {
        let project = snapshot.val();
        if (project) {
          const { pages, sections } = project;

          this.setState({
            ...project,
            project,
          });

          if (!pages) {
            const newSections = sections || [];
            this.setState({
              pages: [this.generateStarterPage(newSections)],
              activePageIndex: 0,
            });
          }
        } else {
          navigate(BASE_PATH + "/404");
        }
        this.setState({
          projectLoaded: true,
        });
        setTimeout(() => {
          this.bindImages();
        },0);
      });
    }
    document.querySelector("body").classList.remove(window.ld_author_main);
  }

  togglePageNavigation = () => {
    this.setState({
      pageNavigationShowing: !this.state.pageNavigationShowing,
    });
  };

  toastShow = (toastState) => {
    this.setState({
      toastShow: false,
    });
    setTimeout(() => {
      this.setState({
        ...toastState,
      });
    }, 200);
  };

  /**
   *
   * @param {string} type
   */
  addMenuItem = async (type) => {
    let newItem =
      type === "module"
        ? this.generatePageModule()
        : this.generateStarterPage();

    const currentPages = [...this.state.pages, newItem];
    const projectActivePage = currentPages.length - 1;

    this.toastShow({
      toastShow: true,
      toastMsg: `Successfully created ${type}`,
      toastVariant: "success",
    });

    return this.savePages(currentPages, projectActivePage);
  };

  addSubPage = async (pageId) => {
    const { pages } = this.state;
    const activePageIndex = this.findIndexById(pages, pageId);
    if (activePageIndex === -1) return false;

    const currentPages = [...pages];
    const subPageArr = currentPages[activePageIndex].subPages
      ? [...currentPages[activePageIndex].subPages]
      : [];
    currentPages[activePageIndex].subPages = [
      ...subPageArr,
      this.generateStarterPage(),
    ];
    const activeSubPageIndex =
      currentPages[activePageIndex].subPages.length - 1;

    this.toastShow({
      toastShow: true,
      toastMsg: `Successfully created page`,
      toastVariant: "success",
    });

    this.savePages(currentPages, activePageIndex, activeSubPageIndex);
  };

  /**
   *
   * @param {Array} items
   * @param {String} id
   */
  findIndexById = (items, id) => items.findIndex((x) => x.id === id);

  /**
   *
   * @param {String} pageId
   */
  setActivePage = async (pageId, parentPageId) => {
    const { pages } = this.state;
    let subPageId;
    if (parentPageId) {
      subPageId = pageId;
      pageId = parentPageId;
    }

    const activePageIndex = this.findIndexById(pages, pageId);
    const activeSubPageIndex = subPageId
      ? this.findIndexById(pages[activePageIndex]?.subPages || [], subPageId)
      : null;

    if (activePageIndex === -1) return false;

    this.savePages(pages, activePageIndex, activeSubPageIndex);
  };

  /**
   *
   * @param {Number} colCount - number of columns for new section to have
   */
  sectionAdd = (colCount) => {
    const { pages, activePageIndex, activeSubPageIndex } = this.state;
    const currentPages = [...pages];

    const currentPage = currentPages[activePageIndex]?.subPages
      ? currentPages[activePageIndex]?.subPages[activeSubPageIndex]
      : currentPages[activePageIndex];

    if (!currentPage) return false;

    currentPage.sections = currentPage.sections || [];

    const cols = [];

    for (let i = 0; i < Math.max(colCount, 1); i++) {
      cols.push({
        id: uuidv4(),
        html: "",
        config: {}, // TODO - this is unused for now, but eventually could have col level configuration
      });
    }

    const currentSections = [
      ...currentPage.sections,
      {
        id: uuidv4(),
        cols,
        config: {},
      },
    ];
    currentPage.sections = currentSections;
    this.savePages(currentPages);
    this.hidePopoverSectionAdd();
  };

  showPopoverSectionAdd = () =>
    this.setState({
      sectionAddPopoverShow: true,
    });

  hidePopoverSectionAdd = () =>
    this.setState({
      sectionAddPopoverShow: false,
    });

  showPopoverSectionProperties = (id) =>
    this.setState({
      sectionPropertiesPopoverShow: id,
    });

  hidePopoverSectionProperties = () => {
    this.setState({
      sectionPropertiesPopoverShow: null,
    });
    this.savePages();
  };

  /**
   *
   * @param {String} templateHtml - string literal html template
   * @param {Object} templateConfig - default configuration for selected template
   */
  addBlock = async (templateHtml, templateConfig, templateJson = null) => {
    const {
      activeColumnId,
      activeSectionId,
      pages,
      activePageIndex,
      activeSubPageIndex,
    } = this.state;
    const currentPages = [...pages];

    const currentPage = currentPages[activePageIndex]?.subPages
      ? currentPages[activePageIndex]?.subPages[activeSubPageIndex]
      : currentPages[activePageIndex];

    if (!currentPage) return false;

    // get section
    currentPage.sections = currentPage.sections || [];
    const currentSections = [...currentPage.sections];
    const sectionIndex = this.findIndexById(currentSections, activeSectionId);

    // get col
    currentSections[sectionIndex].cols =
      currentPage.sections[sectionIndex].cols || [];
    const currentCols = [...currentSections[sectionIndex].cols];
    const colIndex = this.findIndexById(currentCols, activeColumnId);

    // Add html
    currentSections[sectionIndex].cols[colIndex].html = templateHtml;
    currentSections[sectionIndex].cols[colIndex].json = templateJson;

    currentSections[sectionIndex].config = {
      ...currentSections[sectionIndex].config,
      ...templateConfig,
    };

    // Update local object
    currentPage.sections = currentSections;

    // Update remote object
    this.savePages(currentPages);
  };

  /**
   *
   */
  hideBlockModal = () => {
    this.setState({
      showBlockModal: false,
      activeSectionId: null,
      activeColumnId: null,
    });
  };

  /**
   *
   * @param {*} activeSectionId
   * @param {*} activeColumnId
   */
  showBlockModal = (activeSectionId, activeColumnId, activeColCount) =>
    this.setState({
      showBlockModal: true,
      activeSectionId,
      activeColumnId,
      activeColCount,
    });

  /**
   *
   */
  componentWillUnmount() {
    // Restore this wrapper class ( had to be removed in this context to prevent framework / editor conflicts)
    document.querySelector("body").classList.add(window.ld_author_main);
    window.removeEventListener('resize', this.updateScrollContainerHeight);
  }

  /**
   *
   * @param {Object} evt - event object
   * @param {String} sectionId - section id
   * @param {String} columnId - column id
   * @param {Number} cursorPos - position of cursor
   */
  handleContentEdit = (
    evt,
    sectionId,
    columnId,
    cursorPos = null,
    json = null
  ) => {
    const { pages, activePageIndex, activeSubPageIndex } = this.state;

    const currentPages = [...pages];

    const currentPage = currentPages[activePageIndex]?.subPages
      ? currentPages[activePageIndex]?.subPages[activeSubPageIndex]
      : currentPages[activePageIndex];

    if (!currentPage) return false;

    const currentSections = [...currentPage.sections];
    const sectionIndex = this.findIndexById(currentSections, sectionId);

    const currentCols = currentSections[sectionIndex].cols;
    const columnIndex = this.findIndexById(currentCols, columnId);

    if (evt) {
      currentSections[sectionIndex].cols[columnIndex].html = sanitizeHtml(
        evt.target.innerHTML,
        sanitizeHtmlOptions
      );
    }
    currentSections[sectionIndex].cols[columnIndex].json = json;
    currentPage.sections = currentSections;

    this.setState({
      pages: currentPages,
    });

    setTimeout(() => {
      this.bindImages();
    },0);

    if (cursorPos !== null) {
      const textAreaActive = document
        .getElementById("code-editor" + sectionId + columnId)
        .querySelector("textarea");
      textAreaActive && textAreaActive.focus();
      textAreaActive && setCursorPos(textAreaActive, cursorPos.start);
    }
  };

  /**
   *
   * @param {*} project
   */
  handlePublish = (project) => {
    // TODO - this is just illustrating creating a basic zip. Ideally would be packaged up actual project files
    const zip = new JSZip();

    zip.file("Hello.txt", "Hello World\n");

    const img = zip.folder("images");

    img.file("smile.gif", "", { base64: true });

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${project.name}.zip`);
    });
  };

  /**
   *
   * @param {Object} project
   */
  handlePreview = async (project) => {
    const { userID, projectID } = this.props;

    try {
      await ApiConnector.updateProject(userID, projectID, { ...project });
      navigate(BASE_PATH + `/project/${userID}/${projectID}`);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   *
   * @param {*} id
   */
  handleDeleteSection = async (id) => {
    const { pages, activePageIndex, activeSubPageIndex } = this.state;

    const currentPages = [...pages];

    const currentPage = currentPages[activePageIndex]?.subPages
      ? currentPages[activePageIndex]?.subPages[activeSubPageIndex]
      : currentPages[activePageIndex];

    if (!currentPage) return false;

    const currentSections = [...currentPage.sections];
    const sectionIndex = currentSections.findIndex((x) => x.id === id);

    currentSections.splice(sectionIndex, 1);
    currentPage.sections = currentSections;

    this.savePages(currentPages);
  };

  /**
   *
   * @param {*} dragObj
   */
  handleDragAndDrop = async (dragObj) => {
    if (!dragObj || !dragObj.source || !dragObj.destination) return false;
    const oldPos = dragObj.source.index;
    const newPos = dragObj.destination.index;
    const { pages, activePageIndex } = this.state;
    const currentPages = [...pages];

    currentPages[activePageIndex].sections =
      currentPages[activePageIndex].sections || [];

    const currentSections = [...currentPages[activePageIndex].sections];
    const itemToReorder = currentSections[oldPos];

    currentSections.splice(oldPos, 1);
    currentSections.splice(
      Math.min(currentSections.length, newPos),
      0,
      itemToReorder
    ); // add to new

    currentPages[activePageIndex].sections = currentSections;

    this.savePages(currentPages);
  };

  /**
   * @desc updates state immediately with modified page data, then communicates with ApiConnector to save serverside
   * @param {Object[]} pages
   * @param {Number} activePageIndex
   */
  savePages = async (
    pages = this.state.pages,
    activePageIndex = this.state.activePageIndex,
    activeSubPageIndex = this.state.activeSubPageIndex
  ) => {
    const { userID, projectID } = this.props;

    this.setState({
      pages,
      activePageIndex,
      activeSubPageIndex,
    });
    try {
      await ApiConnector.updateProject(userID, projectID, {
        pages,
        activePageIndex,
        activeSubPageIndex,
      });

      setTimeout(() => {
        this.bindImages();
      },0);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   *
   * @desc modify in state on Change, prior to the blur where actualy save
   * @param {*} pageId
   * @param {*} newValue
   */
  modifyPageName = (pageId, newValue, parentPageId) => {
    const { pages } = this.state;
    let subPageId;
    if (parentPageId) {
      subPageId = pageId;
      pageId = parentPageId;
    }

    const currentPages = [...pages];
    const updateIndex = currentPages.findIndex((x) => x.id === pageId);
    if (subPageId) {
      const subPageIndex = currentPages[updateIndex].subPages.findIndex(
        (x) => x.id === subPageId
      );
      currentPages[updateIndex].subPages[subPageIndex].title = newValue;
    } else {
      currentPages[updateIndex].title = newValue;
    }
    this.setState({
      pages: currentPages,
    });
  };

  toggleModuleExpanded = (pageId) => {
    const { pages } = this.state;

    const currentPages = [...pages];
    const updateIndex = currentPages.findIndex((x) => x.id === pageId);

    if (currentPages[updateIndex]) {
      currentPages[updateIndex].expanded = !currentPages[updateIndex].expanded;
    }

    this.setState({
      pages: currentPages,
    });
  };

  /**
   *
   * @desc Adds a new page. Modify this template to change the defaults for newly added pages
   * @param {Array} sections
   */
  generateStarterPage = (sections = []) => {
    return { title: "Untitled Page", id: uuidv4(), sections };
  };

  generatePageModule = () => {
    return {
      title: "Untitled Module",
      id: uuidv4(),
      subPages: [],
      expanded: true,
      module: true,
    };
  };

  /**
   *
   * @param {*} dragObj
   */
  handlePageDragAndDrop = (dragObj) => {
    if (!dragObj || !dragObj.source || !dragObj.destination) return false;
    const { pages } = this.state;
    const oldPos = dragObj.source.index;
    const newPos = dragObj.destination.index;
    const currentPages = [...pages];
    const itemToReorder = currentPages[oldPos];

    currentPages.splice(oldPos, 1);
    currentPages.splice(
      Math.min(currentPages.length, newPos),
      0,
      itemToReorder
    ); // add to new

    this.savePages(currentPages, newPos);
  };

  handleSubPageDragAndDrop = (dragObj) => {
    if (!dragObj || !dragObj.source || !dragObj.destination) return false;
    // Todo - make work
    return false;
  };

  /**
   *
   * @param {*} pageId
   * @param {*} parentPageId
   */
  handleDeletePage = (pageId, parentPageId = null) => {
    const { pages, activePageIndex, activeSubPageIndex } = this.state;

    let subPageId;

    if (parentPageId) {
      subPageId = pageId;
      pageId = parentPageId;
    }

    const updateIndex = pages.findIndex((x) => x.id === pageId);

    if (!pages[updateIndex]) return false;

    const subPageIndex = subPageId
      ? pages[updateIndex]?.subPages.findIndex((x) => x.id === subPageId)
      : -1;

    // if (subPageId && !pages[updateIndex]?.subPages[subPageIndex]) return false;

    const currentPages = [...pages];

    const deletedPageType =
      !parentPageId && currentPages[updateIndex].module ? "module" : "page";

    if (subPageId) {
      if (currentPages[updateIndex].subPages.length === 1) {
        currentPages[updateIndex].subPages = [];
      } else {
        currentPages[updateIndex].subPages.splice(subPageIndex, 1);
      }
    } else {
      currentPages.splice(updateIndex, 1);
    }

    if (currentPages.length === 0) {
      this.setState({
        toastShow: true,
        toastMsg:
          "Project must contain at least one page or module, generated new blank page",
        toastVariant: "danger",
      });
      currentPages.push(this.generateStarterPage());
    } else {
      this.toastShow({
        toastShow: true,
        toastMsg: `Successfully deleted ${deletedPageType}`,
        toastVariant: "success",
      });
    }

    const projectActivePage = Math.min(
      activePageIndex,
      currentPages.length - 1
    );
    const projectActiveSubPage =
      subPageId && currentPages[updateIndex].subPages
        ? Math.min(
          activeSubPageIndex,
          currentPages[updateIndex].subPages.length - 1
        )
        : activeSubPageIndex;

    this.savePages(currentPages, projectActivePage, projectActiveSubPage);
  };

  /**
   *
   * @param {String} sectionId
   */
  toggleCodeView = (sectionId) => {
    const { pages, activePageIndex, activeSubPageIndex } = this.state;

    const currentPages = [...pages];

    const currentPage = currentPages[activePageIndex]?.subPages
      ? currentPages[activePageIndex]?.subPages[activeSubPageIndex]
      : currentPages[activePageIndex];

    if (!currentPage) return false;

    currentPage.sections = currentPage.sections || [];

    const currentSections = [...currentPage.sections];
    const sectionIndex = this.findIndexById(currentSections, sectionId);

    if (!currentPage.sections[sectionIndex]) return false;

    currentPage.sections[sectionIndex].codeView = !currentPage.sections[
      sectionIndex
    ].codeView;

    this.savePages(currentPages);
  };

  updateSectionConfig = (sectionId, updatedConfig) => {
    const { pages, activePageIndex, activeSubPageIndex } = this.state;
    const currentPages = [...pages];

    const currentPage = currentPages[activePageIndex]?.subPages
      ? currentPages[activePageIndex]?.subPages[activeSubPageIndex]
      : currentPages[activePageIndex];

    if (!currentPage) return false;

    currentPage.sections = currentPage.sections || [];

    const currentSections = [...currentPage.sections];
    const sectionIndex = this.findIndexById(currentSections, sectionId);

    currentPage.sections[sectionIndex].config = {
      ...currentPage.sections[sectionIndex].config,
      ...updatedConfig,
    };

    this.setState({
      pages: currentPages,
    });
  };

  getTemplate = (col = {}, sectionId) => {
    return col.json ? (
      <div key={col.id} className="advanced-template-preview w-100">
        <button
          title="Edit"
          className="btn-advanced-template-edit"
          onClick={() => {
            this.showAdvancedEditor(col, sectionId);
          }}
        >
          Edit
        </button>
        <AdvancedContent json={{ ...col.json }} id={col.id} />
      </div>
    ) : (
        <div
          key={col.id}
          className="content-editable-col"
          onBlur={(e) => {
            this.handleContentEdit(e, sectionId, col.id);
            this.savePages();
          }}
          contentEditable
          dangerouslySetInnerHTML={{
            __html: col.html,
          }}
        ></div>
      );
  };

  showAdvancedEditor = (col, sectionId) => {
    this.setState({
      showModal: true,
      modalContent: (
        <AdvancedEditor
          json={{ ...col.json }}
          id={col.id}
          onClose={() => {
            this.setState({
              showModal: false,
            });
          }}
          onSave={(updatedJson) => {
            this.setState({
              showModal: false,
            });
            this.saveAdvancedContent(col, sectionId, updatedJson);
          }}
        />
      ),
    });
  };

  saveAdvancedContent = (col, sectionId, json) => {
    this.handleContentEdit(null, sectionId, col.id, null, json);
    this.savePages();
  };

  /**
   *
   * @param {Object} col - column object
   * @param {String} sectionId
   * @param {Number} colCount
   */
  renderWysiwyg = (col, sectionId, colCount) => {
    return (col.html && col.html !== "") || col.json ? (
      this.getTemplate(col, sectionId)
    ) : (
        <AddBlockBtn
          handleClick={this.showBlockModal.bind(
            this,
            sectionId,
            col.id,
            colCount
          )}
          key={"empty-editor" + sectionId + col.id}
        />
      );
  };

  /**
   *
   * @param {Object} col - column object
   * @param {String} sectionId
   * @param {Number} colCount
   */
  renderEditor = (col, sectionId, colCount) => {
    return (
      (col.json && (
        <p className="text-danger text-center mx-auto w-50 icon-match">
          <div className="mt-3 icon-large">
            <Warning />
          </div>{" "}
          <br />
          <strong>Advanced Template</strong>
          <br />
          <p>
            This template must be edited in the{" "}
            <button
              className="btn-quiet text-underline"
              onClick={this.showAdvancedEditor.bind(this, col, sectionId)}
            >
              advanced editor
            </button>
          </p>
        </p>
      )) ||
      (col.html && (
        <Editor
          id={"code-editor" + sectionId + col.id}
          key={"code-editor" + sectionId + col.id}
          value={cleanIndentation(col.html)}
          onValueChange={(code) => {
            const cursorPos = getCursorPos(
              document
                .getElementById("code-editor" + sectionId + col.id)
                .querySelector("textarea")
            );
            const e = {};
            e.target = {
              ...e.target,
              innerHTML: code,
            };
            this.handleContentEdit(
              e,
              sectionId, // section ID
              col.id,
              cursorPos
            );
          }}
          onBlur={() => {
            this.savePages();
          }}
          highlight={(code) => highlight(code, languages.html)}
          className="text-editing"
        />
      )) || (
        <AddBlockBtn
          handleClick={this.showBlockModal.bind(
            this,
            sectionId,
            col.id,
            colCount
          )}
          key={"empty-code-editor" + sectionId + col.id}
        />
      )
    );
  };

  /**
   *
   * @param {Object} section - section object
   * @param {Number} index - index for current section
   * @returns Draggable for section editor
   */
  renderSection = (section, index) => {
    const { id, codeView, cols, config } = section;
    const sectionClassName = codeView
      ? "section-code-view"
      : getSectionClassName(section);

    return (
      <Draggable
        key={`draggable-section-${id}`}
        draggableId={`draggable-section-${id}`}
        index={index}
      >
        {(provided) => (
          <div
            className="content-section"
            ref={provided.innerRef}
            style={{...provided.draggableProps.style, minHeight: this.state.fullHeightSection ? this.state.containerHeight : 0}}
            {...provided.draggableProps}
            key={`content-section-${id}`}
            index={index}
          >
            {/* Row element */}
            <div className={`content-editable-row ${sectionClassName}`}>
              {(!codeView || !codeViewEnabled) &&
                cols &&
                cols.map((col) => this.renderWysiwyg(col, id, cols.length))}

              {codeView &&
                cols &&
                codeViewEnabled &&
                cols.map((col) => this.renderEditor(col, id, cols.length))}
            </div>
            {/* Section Level Controls */}
            <div className="content-controls">
              <div
                disabled={this.state.fullHeightSection}
                className={`w-100 text-center icon-match text-gray-light ${this.state.fullHeightSection && 'disabled'}`}
                {...provided.dragHandleProps}
              >
                <Drag title="drag and drop" />
              </div>
              <button
                className="btn-quiet icon-match text-gray-light"
                onClick={() => {
                  this.showPopoverSectionProperties(id);
                }}
                ref={(input) => {
                  this[`sectionPropertiesBtn-${id}`] = input;
                }}
              >
                <Pencil title="edit section" />
              </button>
              <Overlay
                show={this.state.sectionPropertiesPopoverShow === id}
                placement="left"
                target={this[`sectionPropertiesBtn-${id}`]}
                rootClose={true}
                onHide={this.hidePopoverSectionProperties}
              >
                <Popover className="properties-panel">
                  <PropertiesPanel
                    config={config}
                    id={id}
                    updateSectionConfig={this.updateSectionConfig}
                  />
                </Popover>
              </Overlay>
              {codeViewEnabled && (
                <button
                  className={`btn-quiet icon-match text-gray-light ${codeView ? "text-gray-dark active" : ""
                    }`}
                  onClick={() => {
                    this.toggleCodeView(id);
                  }}
                >
                  <Code title="edit code" />
                </button>
              )}
              <button
                className="btn-quiet icon-match text-gray-light"
                onClick={() => {
                  this.handleDeleteSection(id);
                }}
              >
                <Trash title="delete section" />
              </button>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  /**
   *
   */
  renderSectionAddPopover = () => (
    <>
      <button
        className="py-3 btn-quiet btn-add-section"
        id="Popover1"
        onClick={this.showPopoverSectionAdd}
        ref={this.sectionAddButton}
        tabIndex={0}
      >
        <PlusBlock title="Add Content" /> <strong>ADD CONTENT</strong>
      </button>
      <Overlay
        show={this.state.sectionAddPopoverShow}
        placement="top"
        target={this.sectionAddButton}
        rootClose={true}
        onHide={this.hidePopoverSectionAdd}
        onEnter={() => { }}
      >
        <Popover className="dojo-editor">
          <div className="p-3 add-section-popover">
            <strong className="text-gray-mid">NUMBER OF COLUMNS FOR ROW</strong>
            <div className="row p-3 text-center">
              <button
                className="col btn-quiet"
                onClick={this.sectionAdd.bind(this, 1)}
              >
                <div className="row">
                  <div className="col outline-col"></div>
                </div>
                <strong className="text-gray">FULL WIDTH</strong>
              </button>
              <button
                className="col btn-quiet"
                onClick={this.sectionAdd.bind(this, 2)}
              >
                <div className="row">
                  <div className="col outline-col"></div>
                  <div className="col outline-col"></div>
                </div>
                <strong className="text-gray">TWO COLUMN</strong>
              </button>
              <button
                className="col btn-quiet"
                onClick={this.sectionAdd.bind(this, 3)}
              >
                <div className="row">
                  <div className="col outline-col"></div>
                  <div className="col outline-col"></div>
                  <div className="col outline-col"></div>
                </div>
                <strong className="text-gray">THREE COLUMN</strong>
              </button>
            </div>
          </div>
        </Popover>
      </Overlay>
    </>
  );

  /**
   *
   * @param {Object} activationData
   */
  activatePage = ({ hasSubPages, page, isSubPage, parentPage }) => {
    if (hasSubPages) {
      this.setActivePage(page.subPages[0].id, page.id);
    } else if (isSubPage) {
      this.setActivePage(page.id, parentPage.id);
    } else {
      this.setActivePage(page.id);
    }
  };

  /**
   *
   * @param {*} provided
   * @param {*} index
   * @param {*} page
   */
  renderMenuItem = (
    provided,
    index,
    page,
    parentPage = {},
    isSubPage = false
  ) => {
    const { activePageIndex, activeSubPageIndex } = this.state;
    const itemActive =
      index === activePageIndex ||
      (index.index === activePageIndex &&
        index.subPageIndex === activeSubPageIndex);
    // const isDraggable = !isSubPage;
    const isDraggable = true;
    const hasSubPages = page.subPages;
    const isModulePage = page.module;
    const draggableProps = isDraggable
      ? {
        ref: provided.innerRef,
        ...provided.draggableProps,
      }
      : {};
    const menuModuleClass = isModulePage
      ? `menu-module ${page.expanded ? " menu-module-expanded" : ""}`
      : "";
    const activatePage = this.activatePage.bind(this, {
      hasSubPages,
      page,
      isSubPage,
      parentPage,
    });

    index = index.index || index;

    return (
      <li
        key={`menu-item-${page.id}`}
        {...draggableProps}
        index={index}
        className={`${menuModuleClass} ${itemActive ? "menu-btn-selected" : ""
          }`}
      >
        {isModulePage && <div
          className="expander"
          onClick={(e) => {
            isModulePage
              ? this.toggleModuleExpanded(page.id)
              : e.stopPropagation();
          }}
        />}
        <div className={`menu-btn btn-quiet icon-match`} onClick={activatePage}>
          {isModulePage ? (
            <Chevron
              title="Toggle Module"
              className={`toggle-icon ${page.expanded ? "toggle-expanded" : ""
                }`}
            />
          ) : (
              <Page title="Page" />
            )}
          <span
            className={`page-actions trash-custom ${!itemActive ? "hidden" : ""
              }`}
            onClick={(e) => {
              this.handleDeletePage(page.id, parentPage.id);
              e.stopPropagation();
            }}
            onKeyPress={(e) => {
              this.handleDeletePage(page.id, parentPage.id);
              e.stopPropagation();
            }}
            tabIndex={0}
            role="button"
          >
            <Trash title="Delete Page" />
          </span>
          <input
            value={page.title}
            onClick={(e) => {
              e.stopPropagation();
              activatePage();
              if (page && !page.expanded) this.toggleModuleExpanded(page.id);
            }}
            onFocus={(e) => {
              e.stopPropagation();
              activatePage();
              if (page && !page.expanded) this.toggleModuleExpanded(page.id);
            }}
            onChange={(e) => {
              this.modifyPageName(page.id, e.target.value, parentPage.id);
            }}
            onBlur={(e) => {
              this.savePages();
            }}
          />
          {isModulePage && (
            <span
              className={`page-actions ${!itemActive ? "hidden" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.addSubPage(page.id);
              }}
              onKeyPress={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.addSubPage(page.id);
              }}
              tabIndex={0}
              role="button"
            >
              <PlusPage title="Add page to section" />
            </span>
          )}
          {isDraggable && (
            <span
              className={`page-actions ${!itemActive ? "hidden" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
              {...provided.dragHandleProps}
            >
              <Drag title="drag and drop" />
            </span>
          )}
        </div>
        {isModulePage && (
          <ul>
            {hasSubPages ? (
              <DragDropContext onDragEnd={this.handleSubPageDragAndDrop}>
                <Droppable droppableId={`page-sub-pages-droparea-${page && page.id}`}>
                  {(provided) => (
                    <ul
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {page.subPages &&
                        page.subPages.map((subPage, subPageIndex) => (
                          <Draggable
                            key={`draggable-subpage-${subPage.id}`}
                            draggableId={`draggable-subpage-${subPage.id}`}
                            index={subPageIndex}
                          >
                            {(provided) =>
                            <>
                              {this.renderMenuItem(
                                provided,
                                { index, subPageIndex },
                                subPage,
                                page,
                                true
                              )}

                                {provided.placeholder}
                              </>
                            }
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
                <li className="empty-subpage noselect">
                  <div>
                    <strong>Module empty - </strong>
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.addSubPage(page.id);
                      }}
                      className="text-bright"
                    >
                      Add new page to module?
                  </span>
                  </div>
                </li>
              )}
          </ul>
        )}
      </li>
    );
  };

  render() {
    const {
      showBlockModal,
      projectLoaded,
      project,
      pages,
      activePageIndex,
      activeSubPageIndex,
    } = this.state;

    const activePage = pages[activePageIndex]?.subPages
      ? pages[activePageIndex].subPages[activeSubPageIndex]
      : pages[activePageIndex];

    const parentPage =
      pages[activePageIndex] !== activePage ? pages[activePageIndex] : null;

    const isModulePage = activePage?.module;

    return (
      <div className="pos-rel container-lg">
        <div className="container-fluid py-3 dojo-editor pos-rel">
          <div className="w-100 d-flex align-items-center justify-content-between">
            <Link to="/" className="btn-home">
              <BackHome />
              Home
            </Link>
            <span className="d-flex align-items-center justify-content-between">
              <button
                className="ld-btn-icon"
                onClick={() => {
                  this.handlePreview(project);
                }}
              >
                PREVIEW <Phone />
              </button>
              <button
                className="ld-btn-icon"
                onClick={() => {
                  this.handlePublish(project);
                }}
              >
                PUBLISH <Publish />
              </button>
            </span>
          </div>
        </div>
        <div className="container-fluid py-3">
          <div className="project-editor">
            <div
              className={`menu-editor text-center ${this.state.pageNavigationShowing ? "page-nav-showing" : ""
                }`}
            >
              <div className="menu-heading">
                <b>PAGE NAVIGATION</b>
                <span className="icon-match">
                  <AddPage
                    title="Add Page"
                    onClick={this.addMenuItem.bind(this, "page")}
                  />
                  <AddFolder
                    title="Add Module"
                    onClick={this.addMenuItem.bind(this, "module")}
                  />
                </span>
              </div>
              <div className={`scroll-area`} ref={this.scrollContainer}>
                {!projectLoaded && (
                  <div className="pos-rel" style={{ height: "500px" }}>
                    <Loading absolute />
                  </div>
                )}
                {projectLoaded && (
                  <DragDropContext onDragEnd={this.handlePageDragAndDrop}>
                    <Droppable droppableId={"menu-droparea"}>
                      {(provided) => (
                        <ul
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {pages &&
                            pages.map((page, index) => (
                              <Draggable
                                key={`draggable-section-${page.id}`}
                                draggableId={`draggable-section-${page.id}`}
                                index={index}
                              >
                                {(provided) =>
                                  this.renderMenuItem(provided, index, page)
                                }
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </div>
            </div>

            <div
              className={`content-editor ${this.state.pageNavigationShowing ? "page-nav-showing" : ""
                }`}
            >
              <div
                className="page-title"
                data-label={`${isModulePage ? "Module" : "Page"} Title`}
              >
                <button
                  className="btn-quiet icon-match text-gray-light py-2 px-3"
                  onClick={this.togglePageNavigation}
                  title="Page Navigation"
                >
                  <Menu />
                </button>
                {activePage && (
                  <>
                    <input
                      value={activePage.title}
                      onChange={(e) => {
                        this.modifyPageName(
                          activePage.id,
                          e.target.value,
                          parentPage?.id
                        );
                      }}
                      onBlur={(e) => {
                        this.savePages();
                      }}
                    />
                  </>
                )}
                <label className="text-center">
                  <small>Full height</small>
                  <input type="checkbox" checked={this.state.fullHeightSection} onChange={e => {
                    this.setState({
                      fullHeightSection: e.target.checked
                    })
                  }} />
                </label>
              </div>
              {/* TODO - the scrollheight may not be sustainable in terms of hiding... tbd */}
              <div className={`scroll-area ${this.state.fullHeightSection ? 'full-height-section' : ''}`} style={{overflowY: this.state.fullHeightSection ? 'hidden' : 'auto'}}>
                {projectLoaded &&
                  !isModulePage &&
                  (activePage?.sections?.length === 0 ||
                    !activePage?.sections) && (
                    <div className="my-4 py-5 text-center dojo-editor ">
                      <div className="m-auto text-center">
                        <h1>Welcome to the project editor</h1>
                        <p>
                          To get started, click "Add Content" and choose a content
                          block
                        </p>
                        {this.state.fullHeightSection && !isModulePage && <div className="my-4 py-5">
                          <div className="py-3">
                          {this.renderSectionAddPopover()}
                          </div>
                        </div>}
                      </div>
                    </div>
                  )}
                {projectLoaded &&
                  isModulePage &&
                  (activePage?.sections?.length === 0 ||
                    !activePage?.sections) && (
                    <div className="my-4 py-5 text-center dojo-editor noselect">
                      <h1>This is an empty module.</h1>
                      <p>
                        To get started,{" "}
                        <strong>
                          {" "}
                          <a
                            href="#add-sub-page"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.addSubPage(activePage?.id);
                            }}
                          >
                            Add a page (+)
                          </a>
                        </strong>{" "}
                        to this module.
                      </p>
                    </div>
                  )}
                {projectLoaded && activePage?.sections?.length !== 0 && (
                  <DragDropContext onDragEnd={this.handleDragAndDrop}>
                    <Droppable droppableId={"droparea"}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {activePage?.sections &&
                            activePage.sections.map((section, index) =>
                              this.renderSection(section, index)
                            )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <div className={`${window.ld_author_main} text-center`}>
                  {!isModulePage && this.renderSectionAddPopover()}
                  {!projectLoaded && (
                    <div className="pos-rel" style={{ height: "500px" }}>
                      <Loading absolute />
                    </div>
                  )}
                  <ModalAddContentBlock
                    show={showBlockModal}
                    onHide={this.hideBlockModal}
                    onSelection={this.addBlock}
                    colCount={this.state.activeColCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.toastShow && (
          <ToastMinimal
            initShow={this.state.toastShow}
            msg={this.state.toastMsg}
            onClose={() => {
              setTimeout(() => {
                this.setState({ toastShow: false });
              }, 500);
            }}
            variant={this.state.toastVariant}
          />
        )}

        <Modal
          className="modal-minimal modal-fluid"
          show={this.state.showModal}
          onHide={() => {
            this.setState({
              showModal: false,
            });
          }}
        >
          {this.state.modalContent}
        </Modal>
      </div>
    );
  }
}

export default ProjectEditor;
