import React from "react";

const ProjectsEmpty = (props) => (
  <div className="text-center py-5">
    <h3 className="font-size-lg">There are no previous projects to display</h3>
    <p>
      Get started by creating return to Current Projects tab and creating a new
      project.
    </p>
  </div>
);

export default ProjectsEmpty;
